import {
    ApiExternalVariableTypesConfigurationsIdDeleteRequest,
    ApiUsersIdDeleteRequest,
    UserOut,
    UsersApi
} from '../../../api';
import {action, makeObservable, observable} from 'mobx';
import {ConfirmDeleteDialogState} from '../Common/ConfirmDeleteDialogState';
import Swal from 'sweetalert2';

export class AccountListViewState {
    @observable items: UserOut[];
    private isFetching: boolean;
    
    constructor(private _usersService : UsersApi) {
        makeObservable(this);
        this.loadData();
    }

    @action loadData() {
        if (this.isFetching)
            return;

        this.isFetching = true;
        this._usersService.apiUsersGet().then(r => this.items =  r).finally(() => this.isFetching = false);
    }

    delete(s: string) {
        Swal.fire({
            title: 'Do you want to remove item?',
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: `No`,
        }).then((result) => {
            if (result.isConfirmed) {
                this._usersService.apiUsersIdDelete(new class implements ApiUsersIdDeleteRequest {
                    id:string = s
                }).then(() => {
                    console.log('reload');
                    window.location.reload()
                });
            }
        })
        
    }
}