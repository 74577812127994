import * as React from 'react';
import {mainStore, MainStoreProps} from '../components/mainStore';
import {observer} from 'mobx-react';
import {Redirect, Route, Router, useLocation} from 'react-router';
import {createBrowserHistory, History} from 'history';
import {Layout} from '../components/views/Layout';
import {ExternalVariablesConfiguration} from '../components/views/VariablesConfiguration/ExternalVariablesConfiguration';
import {InternalVariablesConfiguration} from '../components/views/VariablesConfiguration/InternalVariablesConfiguration';
import {InternalVariablesConfigurationState} from '../components/views/VariablesConfiguration/InternalVariablesConfigurationState';
import container from '../DIContainer';
import {ConfirmDeleteDialogState} from '../components/views/Common/ConfirmDeleteDialogState';
import {EditInternalVariablesConfigurationState} from '../components/views/VariablesConfiguration/EditInternalVariablesConfigurationState';
import {CommonService} from '../components/services/commonService';
import {EditExternalVariablesConfigurationState} from '../components/views/VariablesConfiguration/EditExternalVariablesConfigurationState';
import {VariableImportConfigurationState} from '../components/views/VariableImports/VariableImportConfigurationState';
import {VariableImportConfigurationList} from '../components/views/VariableImports/VariableImportConfigurationList';
import {VariableImportWizardState} from '../components/views/VariableImports/Wizard/VariableImportWizardState';
import {VariableImportWizard} from '../components/views/VariableImports/Wizard/VariableImportWizard';
import {LayoutState} from '../components/views/LayoutState';
import {InternalVariableValues} from '../components/views/CurrentValues/InternalVariableValues';
import {ExternalVariableValues} from '../components/views/CurrentValues/ExternalVariableValues';
import {ExternalVariableValuesState} from '../components/views/CurrentValues/ExternalVariableValuesState';
import {InternalVariableValuesState} from '../components/views/CurrentValues/InternalVariableValuesState';
import {EditInternalVariablesConfiguration} from '../components/views/VariablesConfiguration/EditInternalVariablesConfiguration';
import {EditExternalVariablesConfiguration} from '../components/views/VariablesConfiguration/EditExternalVariablesConfiguration';
import {ExternalVariablesConfigurationState} from '../components/views/VariablesConfiguration/ExternalVariablesConfigurationState';
import {WorkerStatus} from '../components/views/WorkerStatus/WorkerStatus';
import {WorkerStatusState} from '../components/views/WorkerStatus/WorkerStatusState';
import {
    AccountApi,
    ExternalVariableTypesConfigurationApi,
    InternalVariablesConfigurationApi, UsersApi, UtilsApi,
    VariablesImportApi,
    VariableValuesApi
} from '../api';
import {ChangePasswordState} from '../components/views/Accounts/ChangePasswordState';
import {ChangePassword} from '../components/views/Accounts/ChangePassword';
import {AccountListViewState} from '../components/views/Accounts/AccountListViewState';
import {AccountListView} from '../components/views/Accounts/AccountListView';
import {AccountEdit} from '../components/views/Accounts/AccountEdit';
import {AccountEditState} from '../components/views/Accounts/AccountEditState';
import {LoginState} from '../components/views/Accounts/LoginState';
import {Login} from '../components/views/Accounts/Login';

export const history: History = createBrowserHistory();

const layoutState = new LayoutState();

export const Routes: React.FC<MainStoreProps> = (observer(({state}) => {
    return (
        <Router history={history}>
            <Route exact path='/'>
                <Redirect exact to='/internal-variable-values'/>
            </Route>

            <Route path="/login" component={() =>{
                const state = new LoginState(new AccountApi(), '/')
                return <Login state={state}/>
            }} />

            {/*{typeof state.user === typeof undefined && <Redirect to="/login"/>}*/}

            <Route exact path='/internal-variables-configuration' component={() => {
                const state = new InternalVariablesConfigurationState(new InternalVariablesConfigurationApi(), container.get(CommonService));
                state.confirmDeleteDialogState = new ConfirmDeleteDialogState();
                return <Layout state={layoutState} path='Internal Variables Configuration' content={<InternalVariablesConfiguration state={state}/>}/>}}/>

            <Route exact path='/external-variables-configuration' component={() => {
                const state = new ExternalVariablesConfigurationState(new ExternalVariableTypesConfigurationApi(), container.get(CommonService));
                state.confirmDeleteDialogState = new ConfirmDeleteDialogState();
                return <Layout state={layoutState} path='External Variables Configuration' content={<ExternalVariablesConfiguration state={state}/>}/>}}/>

            <Route exact path='/variable-import-configurations' component={() => {
                const state = new VariableImportConfigurationState(new VariablesImportApi());
                state.confirmDeleteDialogState = new ConfirmDeleteDialogState();
                return <Layout state={layoutState} path='Variable Import Configurations' content={<VariableImportConfigurationList state={state}/>}/>}}/>

            <Route exact path='/variable-import-configurations/edit' component={() => {
                const state =  new VariableImportWizardState(new VariablesImportApi());
                return <Layout state={layoutState} path='Variable Import Wizard' content={<VariableImportWizard state={state} />}/>}}/>

            <Route exact path='/internal-variable-values' component={() => {
                const state = new InternalVariableValuesState(new VariableValuesApi());
                return <Layout state={layoutState} path='Internal Variable Values' content={<InternalVariableValues state={state} />}/>}}/>


            <Route exact path='/external-variable-values' component={() => {
                const state = new ExternalVariableValuesState(new VariableValuesApi());
                return <Layout state={layoutState} path='External Variable Values' content={<ExternalVariableValues state={state} />}/>}}/>

            <Route path='/external-variables-configuration/edit/:id?' component={() => {
                let url = useLocation().pathname;
                let id = url.substring(url.lastIndexOf('/') + 1);
                const state =  new EditExternalVariablesConfigurationState(
                    new ExternalVariableTypesConfigurationApi(), Number(id) || undefined);
                return <Layout state={layoutState} path='Variable Import Wizard' content={<EditExternalVariablesConfiguration state={state} />}/>}}/>

            <Route path='/internal-variables-configuration/edit/:id?' component={() => {
                let url = useLocation().pathname;
                let id = url.substring(url.lastIndexOf('/') + 1);
                const state =  new EditInternalVariablesConfigurationState(
                    new InternalVariablesConfigurationApi(),
                    new ExternalVariableTypesConfigurationApi(),
                    Number(id) || undefined
                );
                return <Layout state={layoutState} path='Variable Import Wizard' content={<EditInternalVariablesConfiguration state={state} />}/>}}/>

            <Route exact path='/worker-status' component={() => {
                const state = new WorkerStatusState(new UtilsApi());
                return <Layout state={layoutState} path='Worker status' content={<WorkerStatus state={state} />}/>}}/>

            <Route exact path='/password-change' component={() => {
                const state = new ChangePasswordState(new AccountApi(), '/');
                return <Layout state={layoutState} path='Password Change' content={<ChangePassword state={state} />}/>}}/>

            <Route exact path='/users' component={() => {
                const state = new AccountListViewState(new UsersApi());
                return <Layout state={layoutState} path='Users' content={<AccountListView state={state} />}/>}}/>

            <Route path='/users/edit' component={() => {
                const state =  new AccountEditState(new UsersApi());

                return <Layout state={layoutState} path='Variable Import Wizard' content={<AccountEdit state={state} />}/>}}/>
        </Router>
    )
}))