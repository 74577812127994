/* tslint:disable */
/* eslint-disable */
/**
 * sDSRelay API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum InternalVariableType {
    Number = 'Number',
    Time = 'Time',
    Date = 'Date',
    Text = 'Text'
}

export function InternalVariableTypeFromJSON(json: any): InternalVariableType {
    return InternalVariableTypeFromJSONTyped(json, false);
}

export function InternalVariableTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternalVariableType {
    return json as InternalVariableType;
}

export function InternalVariableTypeToJSON(value?: InternalVariableType | null): any {
    return value as any;
}

