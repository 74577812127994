import * as React from 'react';
import {observer} from 'mobx-react';
import {VariableImportWizardState} from './VariableImportWizardState';
import {Button, Paper} from '@material-ui/core';
import {VariableImportStep0} from './Steps/VariableImportStep0';
import {VariableImportStep1} from './Steps/VariableImportStep1';
import {NavLink} from 'react-router-dom';

class VariableImportWizardProps{ 
    state: VariableImportWizardState;
}

export const VariableImportWizard: React.FC<VariableImportWizardProps> = observer(({state}) => {
    return (
        <div>
            <Paper>
                {state.step === 0 && <VariableImportStep0  state={state}/>}
                {state.step === 1 && <VariableImportStep1  state={state}/>}
                {state.step === 1 && <Button style={{"float": "left"}} onClick={() => state.previousStep()} >Previous Step</Button>}
                <NavLink to="/variable-import-configurations" className="text-decoration-none" style={{"float": "left", "padding": 0, "margin": 0 }} ><Button>Cancel</Button></NavLink>
                {state.step === 0 && state.allRequiredFieldsFilled && <Button style={{"float": "right"}} onClick={() => state.nextStep()} >Next Step</Button>}
            </Paper>
        </div>
    )});