/* tslint:disable */
/* eslint-disable */
/**
 * sDSRelay API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExternalVariableTypeDto
 */
export interface ExternalVariableTypeDto {
    /**
     * 
     * @type {number}
     * @memberof ExternalVariableTypeDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ExternalVariableTypeDto
     */
    typeName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalVariableTypeDto
     */
    matchingPattern?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalVariableTypeDto
     */
    defaultResetTime?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalVariableTypeDto
     */
    defaultLifetime?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalVariableTypeDto
     */
    defaultValue?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ExternalVariableTypeDto
     */
    insertDateTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ExternalVariableTypeDto
     */
    lastUpdateDateTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof ExternalVariableTypeDto
     */
    readonly identifier?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExternalVariableTypeDto
     */
    matchOrder: number;
}

export function ExternalVariableTypeDtoFromJSON(json: any): ExternalVariableTypeDto {
    return ExternalVariableTypeDtoFromJSONTyped(json, false);
}

export function ExternalVariableTypeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalVariableTypeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'typeName': !exists(json, 'typeName') ? undefined : json['typeName'],
        'matchingPattern': !exists(json, 'matchingPattern') ? undefined : json['matchingPattern'],
        'defaultResetTime': !exists(json, 'defaultResetTime') ? undefined : json['defaultResetTime'],
        'defaultLifetime': !exists(json, 'defaultLifetime') ? undefined : json['defaultLifetime'],
        'defaultValue': !exists(json, 'defaultValue') ? undefined : json['defaultValue'],
        'insertDateTime': !exists(json, 'insertDateTime') ? undefined : (new Date(json['insertDateTime'])),
        'lastUpdateDateTime': !exists(json, 'lastUpdateDateTime') ? undefined : (new Date(json['lastUpdateDateTime'])),
        'identifier': !exists(json, 'identifier') ? undefined : json['identifier'],
        'matchOrder': json['matchOrder'],
    };
}

export function ExternalVariableTypeDtoToJSON(value?: ExternalVariableTypeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'typeName': value.typeName,
        'matchingPattern': value.matchingPattern,
        'defaultResetTime': value.defaultResetTime,
        'defaultLifetime': value.defaultLifetime,
        'defaultValue': value.defaultValue,
        'insertDateTime': value.insertDateTime === undefined ? undefined : (value.insertDateTime.toISOString()),
        'lastUpdateDateTime': value.lastUpdateDateTime === undefined ? undefined : (value.lastUpdateDateTime.toISOString()),
        'matchOrder': value.matchOrder,
    };
}


