/* tslint:disable */
/* eslint-disable */
/**
 * sDSRelay API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ParameterAttributes {
    None = 'None',
    In = 'In',
    Out = 'Out',
    Lcid = 'Lcid',
    Retval = 'Retval',
    Optional = 'Optional',
    HasDefault = 'HasDefault',
    HasFieldMarshal = 'HasFieldMarshal',
    Reserved3 = 'Reserved3',
    Reserved4 = 'Reserved4',
    ReservedMask = 'ReservedMask'
}

export function ParameterAttributesFromJSON(json: any): ParameterAttributes {
    return ParameterAttributesFromJSONTyped(json, false);
}

export function ParameterAttributesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParameterAttributes {
    return json as ParameterAttributes;
}

export function ParameterAttributesToJSON(value?: ParameterAttributes | null): any {
    return value as any;
}

