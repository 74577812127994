import {action, makeObservable, observable} from 'mobx';

export class VariablesStatusState {
    @observable items: any = [];
    
    constructor() {
        makeObservable(this);
    }
    
    @action setItems(i: any) {
        this.items = i;
    }
}