/* tslint:disable */
/* eslint-disable */
/**
 * sDSRelay API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InternalVariableOperationConfigurationDto,
    InternalVariableOperationConfigurationDtoFromJSON,
    InternalVariableOperationConfigurationDtoFromJSONTyped,
    InternalVariableOperationConfigurationDtoToJSON,
    InternalVariableType,
    InternalVariableTypeFromJSON,
    InternalVariableTypeFromJSONTyped,
    InternalVariableTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface InternalVariableConfigurationDto
 */
export interface InternalVariableConfigurationDto {
    /**
     * 
     * @type {number}
     * @memberof InternalVariableConfigurationDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof InternalVariableConfigurationDto
     */
    key?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternalVariableConfigurationDto
     */
    defaultValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternalVariableConfigurationDto
     */
    resetTime?: string;
    /**
     * 
     * @type {InternalVariableType}
     * @memberof InternalVariableConfigurationDto
     */
    type?: InternalVariableType;
    /**
     * 
     * @type {string}
     * @memberof InternalVariableConfigurationDto
     */
    subTableName?: string | null;
    /**
     * 
     * @type {Array<InternalVariableOperationConfigurationDto>}
     * @memberof InternalVariableConfigurationDto
     */
    operations?: Array<InternalVariableOperationConfigurationDto> | null;
    /**
     * 
     * @type {Date}
     * @memberof InternalVariableConfigurationDto
     */
    insertDateTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InternalVariableConfigurationDto
     */
    lastUpdateDateTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof InternalVariableConfigurationDto
     */
    readonly identifier?: string | null;
}

export function InternalVariableConfigurationDtoFromJSON(json: any): InternalVariableConfigurationDto {
    return InternalVariableConfigurationDtoFromJSONTyped(json, false);
}

export function InternalVariableConfigurationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternalVariableConfigurationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'key': !exists(json, 'key') ? undefined : json['key'],
        'defaultValue': !exists(json, 'defaultValue') ? undefined : json['defaultValue'],
        'resetTime': !exists(json, 'resetTime') ? undefined : json['resetTime'],
        'type': !exists(json, 'type') ? undefined : InternalVariableTypeFromJSON(json['type']),
        'subTableName': !exists(json, 'subTableName') ? undefined : json['subTableName'],
        'operations': !exists(json, 'operations') ? undefined : (json['operations'] === null ? null : (json['operations'] as Array<any>).map(InternalVariableOperationConfigurationDtoFromJSON)),
        'insertDateTime': !exists(json, 'insertDateTime') ? undefined : (new Date(json['insertDateTime'])),
        'lastUpdateDateTime': !exists(json, 'lastUpdateDateTime') ? undefined : (new Date(json['lastUpdateDateTime'])),
        'identifier': !exists(json, 'identifier') ? undefined : json['identifier'],
    };
}

export function InternalVariableConfigurationDtoToJSON(value?: InternalVariableConfigurationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'key': value.key,
        'defaultValue': value.defaultValue,
        'resetTime': value.resetTime,
        'type': InternalVariableTypeToJSON(value.type),
        'subTableName': value.subTableName,
        'operations': value.operations === undefined ? undefined : (value.operations === null ? null : (value.operations as Array<any>).map(InternalVariableOperationConfigurationDtoToJSON)),
        'insertDateTime': value.insertDateTime === undefined ? undefined : (value.insertDateTime.toISOString()),
        'lastUpdateDateTime': value.lastUpdateDateTime === undefined ? undefined : (value.lastUpdateDateTime.toISOString()),
    };
}


