/* tslint:disable */
/* eslint-disable */
/**
 * sDSRelay API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExternalVariableTypeConfigurationIn,
    ExternalVariableTypeConfigurationInFromJSON,
    ExternalVariableTypeConfigurationInFromJSONTyped,
    ExternalVariableTypeConfigurationInToJSON,
} from './';

/**
 * 
 * @export
 * @interface ReorderRequestData
 */
export interface ReorderRequestData {
    /**
     * 
     * @type {Array<ExternalVariableTypeConfigurationIn>}
     * @memberof ReorderRequestData
     */
    orderedItems?: Array<ExternalVariableTypeConfigurationIn> | null;
}

export function ReorderRequestDataFromJSON(json: any): ReorderRequestData {
    return ReorderRequestDataFromJSONTyped(json, false);
}

export function ReorderRequestDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReorderRequestData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orderedItems': !exists(json, 'orderedItems') ? undefined : (json['orderedItems'] === null ? null : (json['orderedItems'] as Array<any>).map(ExternalVariableTypeConfigurationInFromJSON)),
    };
}

export function ReorderRequestDataToJSON(value?: ReorderRequestData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orderedItems': value.orderedItems === undefined ? undefined : (value.orderedItems === null ? null : (value.orderedItems as Array<any>).map(ExternalVariableTypeConfigurationInToJSON)),
    };
}


