/* tslint:disable */
/* eslint-disable */
/**
 * sDSRelay API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum AddressFamily {
    Unspecified = 'Unspecified',
    Unix = 'Unix',
    InterNetwork = 'InterNetwork',
    ImpLink = 'ImpLink',
    Pup = 'Pup',
    Chaos = 'Chaos',
    Ipx = 'Ipx',
    Iso = 'Iso',
    Ecma = 'Ecma',
    DataKit = 'DataKit',
    Ccitt = 'Ccitt',
    Sna = 'Sna',
    DecNet = 'DecNet',
    DataLink = 'DataLink',
    Lat = 'Lat',
    HyperChannel = 'HyperChannel',
    AppleTalk = 'AppleTalk',
    NetBios = 'NetBios',
    VoiceView = 'VoiceView',
    FireFox = 'FireFox',
    Banyan = 'Banyan',
    Atm = 'Atm',
    InterNetworkV6 = 'InterNetworkV6',
    Cluster = 'Cluster',
    Ieee12844 = 'Ieee12844',
    Irda = 'Irda',
    NetworkDesigners = 'NetworkDesigners',
    Max = 'Max',
    Packet = 'Packet',
    ControllerAreaNetwork = 'ControllerAreaNetwork',
    Unknown = 'Unknown'
}

export function AddressFamilyFromJSON(json: any): AddressFamily {
    return AddressFamilyFromJSONTyped(json, false);
}

export function AddressFamilyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddressFamily {
    return json as AddressFamily;
}

export function AddressFamilyToJSON(value?: AddressFamily | null): any {
    return value as any;
}

