import {action, makeObservable, observable, runInAction} from 'mobx';
import {VariablesStatusState} from '../VariablesStatus/VariablesStatusState';

export class HomeState {
    @observable currentTab : number = 0;
    @observable internalVariablesState: VariablesStatusState = new VariablesStatusState();
    @observable externalVariablesState: VariablesStatusState = new VariablesStatusState();
    
    constructor() {
        makeObservable(this);
    }
}