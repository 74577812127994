import {action, makeObservable, observable, runInAction} from 'mobx';
import {AccountApi, ApiAccountLogoutPostRequest} from '../api';
import {history} from '../routing/Routes';

export interface MainStoreProps{
    state: MainStore;
}

export class MainStore{
    @observable user: string | undefined;
    
    constructor(private accountService : AccountApi) {
        makeObservable(this);
        this.getCurrentUser();
    }
    
    @action getCurrentUser(){
        this.accountService.apiAccountCurrentUserPostRaw().then(u => {
            if (u.raw.status === 200) u.value().then(user => {
                this.setUser(user)
            }) 
            else {
                this.setUser(undefined);
                history.push('/login');
            };
        }).catch( e => {
            this.user = undefined
            history.push('/login');
        })
    }

    @action logout() {
        this.accountService.apiAccountLogoutPost(new class implements ApiAccountLogoutPostRequest {}).then(u => {
            this.setUser(undefined);
            history.push('/login');
        }).catch( e => {})
    }

    @action private setUser(u: string | undefined) {
        this.user = u;
    }
}

export const mainStore: MainStore = new MainStore(new AccountApi());