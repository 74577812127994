import {AccountApi, ApiAccountChangePasswordPostRequest} from '../../../api';
import {action, makeObservable, observable} from 'mobx';
import Swal from 'sweetalert2'

export class ChangePasswordState {
    @observable currentPassword?: string;
    @observable newPassword?: string;

    constructor(private accountService : AccountApi, private redirectUrl: string | undefined | null) {
        makeObservable(this);
    }

    @action async submit(x: React.FormEvent<HTMLFormElement>) {
        x.preventDefault();
        
        const redirectUrl = this.redirectUrl || undefined;
        const currentPassword = this.currentPassword;
        const newPassword = this.newPassword;
        
        if (!currentPassword || !newPassword) {
            await Swal.fire('Failed!',
                'Please fill both passwords!',
                'error')
            return;
        }
        
        await this.accountService.apiAccountChangePasswordPost(new class implements ApiAccountChangePasswordPostRequest {
            currentPassword: string = currentPassword!;
            newPassword: string = newPassword!;
        }).then(() => {
            Swal.fire('Good job!',
                'Password changed!',
                'success')
            if (this.redirectUrl) window.location.replace(redirectUrl!);
        }).catch(() => {
            Swal.fire('Failed!',
            'Password not changed!',
            'error').then(() => {
                this.setCurrentPassword(undefined)
                this.setNewPassword(undefined)
            })
        })
    }

    @action setCurrentPassword(value?: string) {
        this.currentPassword = value;
    }

    @action setNewPassword(value?: string) {
        this.newPassword = value;
    }
}