/* tslint:disable */
/* eslint-disable */
/**
 * sDSRelay API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InternalVariableType,
    InternalVariableTypeFromJSON,
    InternalVariableTypeFromJSONTyped,
    InternalVariableTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface InternalVariableConfigurationOut
 */
export interface InternalVariableConfigurationOut {
    /**
     * 
     * @type {number}
     * @memberof InternalVariableConfigurationOut
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof InternalVariableConfigurationOut
     */
    key?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternalVariableConfigurationOut
     */
    defaultValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternalVariableConfigurationOut
     */
    resetTime?: string;
    /**
     * 
     * @type {InternalVariableType}
     * @memberof InternalVariableConfigurationOut
     */
    type?: InternalVariableType;
    /**
     * 
     * @type {string}
     * @memberof InternalVariableConfigurationOut
     */
    subTableName?: string | null;
}

export function InternalVariableConfigurationOutFromJSON(json: any): InternalVariableConfigurationOut {
    return InternalVariableConfigurationOutFromJSONTyped(json, false);
}

export function InternalVariableConfigurationOutFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternalVariableConfigurationOut {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'key': !exists(json, 'key') ? undefined : json['key'],
        'defaultValue': !exists(json, 'defaultValue') ? undefined : json['defaultValue'],
        'resetTime': !exists(json, 'resetTime') ? undefined : json['resetTime'],
        'type': !exists(json, 'type') ? undefined : InternalVariableTypeFromJSON(json['type']),
        'subTableName': !exists(json, 'subTableName') ? undefined : json['subTableName'],
    };
}

export function InternalVariableConfigurationOutToJSON(value?: InternalVariableConfigurationOut | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'key': value.key,
        'defaultValue': value.defaultValue,
        'resetTime': value.resetTime,
        'type': InternalVariableTypeToJSON(value.type),
        'subTableName': value.subTableName,
    };
}


