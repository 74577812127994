/* tslint:disable */
/* eslint-disable */
/**
 * sDSRelay API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InternalVariableOperationConfigurationIn,
    InternalVariableOperationConfigurationInFromJSON,
    InternalVariableOperationConfigurationInFromJSONTyped,
    InternalVariableOperationConfigurationInToJSON,
    InternalVariableType,
    InternalVariableTypeFromJSON,
    InternalVariableTypeFromJSONTyped,
    InternalVariableTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface InternalVariableConfigurationUpdateIn
 */
export interface InternalVariableConfigurationUpdateIn {
    /**
     * 
     * @type {number}
     * @memberof InternalVariableConfigurationUpdateIn
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof InternalVariableConfigurationUpdateIn
     */
    key?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternalVariableConfigurationUpdateIn
     */
    defaultValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternalVariableConfigurationUpdateIn
     */
    resetTime?: string;
    /**
     * 
     * @type {InternalVariableType}
     * @memberof InternalVariableConfigurationUpdateIn
     */
    type?: InternalVariableType;
    /**
     * 
     * @type {string}
     * @memberof InternalVariableConfigurationUpdateIn
     */
    subTableName?: string | null;
    /**
     * 
     * @type {Array<InternalVariableOperationConfigurationIn>}
     * @memberof InternalVariableConfigurationUpdateIn
     */
    operations?: Array<InternalVariableOperationConfigurationIn> | null;
}

export function InternalVariableConfigurationUpdateInFromJSON(json: any): InternalVariableConfigurationUpdateIn {
    return InternalVariableConfigurationUpdateInFromJSONTyped(json, false);
}

export function InternalVariableConfigurationUpdateInFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternalVariableConfigurationUpdateIn {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'key': !exists(json, 'key') ? undefined : json['key'],
        'defaultValue': !exists(json, 'defaultValue') ? undefined : json['defaultValue'],
        'resetTime': !exists(json, 'resetTime') ? undefined : json['resetTime'],
        'type': !exists(json, 'type') ? undefined : InternalVariableTypeFromJSON(json['type']),
        'subTableName': !exists(json, 'subTableName') ? undefined : json['subTableName'],
        'operations': !exists(json, 'operations') ? undefined : (json['operations'] === null ? null : (json['operations'] as Array<any>).map(InternalVariableOperationConfigurationInFromJSON)),
    };
}

export function InternalVariableConfigurationUpdateInToJSON(value?: InternalVariableConfigurationUpdateIn | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'key': value.key,
        'defaultValue': value.defaultValue,
        'resetTime': value.resetTime,
        'type': InternalVariableTypeToJSON(value.type),
        'subTableName': value.subTableName,
        'operations': value.operations === undefined ? undefined : (value.operations === null ? null : (value.operations as Array<any>).map(InternalVariableOperationConfigurationInToJSON)),
    };
}


