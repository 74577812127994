import {action, makeObservable, observable} from 'mobx';
import {DropResult} from 'react-beautiful-dnd';
import {ConfirmDeleteDialogState} from '../Common/ConfirmDeleteDialogState';
import {CommonService} from '../../services/commonService';
import {
    ApiExternalVariableTypesConfigurationsIdDeleteRequest,
    ApiExternalVariableTypesConfigurationsReorderPostRequest,
    ExternalVariableTypeConfigurationIn,
    ExternalVariableTypeOut,
    ExternalVariableTypesConfigurationApi,
    ReorderRequestData
} from '../../../api';
import Swal from 'sweetalert2';

export class ExternalVariablesConfiguration {
    id: number;
    typeName: string;
    matchingPattern: string;
    defaultResetTime: string;
    defaultLifetime: string;
    defaultValue: string;
    matchOrder: number;
}

export class ExternalVariablesConfigurationState {
    @observable externalVariablesConfigurations: ExternalVariablesConfiguration[];
    confirmDeleteDialogState: ConfirmDeleteDialogState;

    constructor(private externalVariableTypesConfigurationService: ExternalVariableTypesConfigurationApi, private commonService: CommonService) {
        makeObservable(this);
        this.loadData();
    }

    @action loadData() {
        this.externalVariableTypesConfigurationService.apiExternalVariableTypesConfigurationsGet().then(value => {
            this.setExternalVariablesConfigurations(value);
        });
    }

    reorder(list: ExternalVariablesConfiguration[], startIndex: number, endIndex: number) {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        for(let i = 0; i < result.length; i++){
            result[i].matchOrder = i;
        }

        return result;
    }
    
    @action onDragEnd(result: DropResult) {
        if (!result.destination) {
            return;
        }

        this.externalVariablesConfigurations = this.reorder(
            this.externalVariablesConfigurations,
            result.source.index,
            result.destination.index
        );
        const externalVariablesConfigurations = this.externalVariablesConfigurations;
        this.externalVariableTypesConfigurationService.apiExternalVariableTypesConfigurationsReorderPost(new class implements ApiExternalVariableTypesConfigurationsReorderPostRequest {
            reorderRequestData: ReorderRequestData = new class implements ReorderRequestData {
                orderedItems: Array<ExternalVariableTypeConfigurationIn> | null = externalVariablesConfigurations;
            }
        }).then(r => {});
    }

    reloadApp() {
        this.commonService.reloadApp();
    }

    @action delete(entity: ExternalVariablesConfiguration) {
        Swal.fire({
            title: 'Do you want to remove item?',
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: `No`,
        }).then((result) => {
            if (result.isConfirmed) {
                this.externalVariableTypesConfigurationService.apiExternalVariableTypesConfigurationsIdDelete(new class implements ApiExternalVariableTypesConfigurationsIdDeleteRequest {
                    id: number = entity.id;
                })
                    .then(x => this.externalVariablesConfigurations.splice(this.externalVariablesConfigurations.indexOf(entity)));
            }
        })
    }

    private setExternalVariablesConfigurations(value: Array<ExternalVariableTypeOut>) {
        this.externalVariablesConfigurations = value.map((x: any) => {
            return x;
        })
    }
}