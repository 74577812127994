import * as React from 'react';
import {observer} from 'mobx-react';
import {Button, Card, Paper, Table, TableBody, TableCell, TableRow} from '@material-ui/core';
import {LoginState} from './LoginState';
import TextField from '@material-ui/core/TextField';
import {Form} from 'react-bootstrap';

export class LoginProps{
    state : LoginState;
}

export const Login: React.FC<LoginProps> = observer(({state}) => {
    return (
        <div>
            <Card style={{maxWidth: '50%', marginLeft: 'auto', marginRight: 'auto'}}>
                <Form onSubmit={async x => await state.submit(x)}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <TextField
                                        required
                                        label="User name"
                                        value={state.userName}
                                        onChange={event => state.setUserName(event.target.value)}
                                        margin="normal"
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        label="Password"
                                        type="password"
                                        autoComplete="current-password"
                                        variant="filled"
                                        value={state.password}
                                        onChange={event => state.setPassword(event.target.value)}
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <Button type="submit" style={{float: 'right'}}>Login</Button>
                </Form>
            </Card>
        </div>
    )
})