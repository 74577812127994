/* tslint:disable */
/* eslint-disable */
/**
 * sDSRelay API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    UserCreateIn,
    UserCreateInFromJSON,
    UserCreateInToJSON,
    UserOut,
    UserOutFromJSON,
    UserOutToJSON,
} from '../models';

export interface ApiUsersIdDeleteRequest {
    id: string;
}

export interface ApiUsersPostRequest {
    userCreateIn?: UserCreateIn;
}

/**
 * 
 */
export class UsersApi extends runtime.BaseAPI {

    /**
     */
    async apiUsersGetRaw(): Promise<runtime.ApiResponse<Array<UserOut>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserOutFromJSON));
    }

    /**
     */
    async apiUsersGet(): Promise<Array<UserOut>> {
        const response = await this.apiUsersGetRaw();
        return await response.value();
    }

    /**
     */
    async apiUsersIdDeleteRaw(requestParameters: ApiUsersIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiUsersIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiUsersIdDelete(requestParameters: ApiUsersIdDeleteRequest): Promise<void> {
        await this.apiUsersIdDeleteRaw(requestParameters);
    }

    /**
     */
    async apiUsersPostRaw(requestParameters: ApiUsersPostRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/Users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserCreateInToJSON(requestParameters.userCreateIn),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiUsersPost(requestParameters: ApiUsersPostRequest): Promise<void> {
        await this.apiUsersPostRaw(requestParameters);
    }

}
