/* tslint:disable */
/* eslint-disable */
/**
 * sDSRelay API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum CallingConventions {
    Standard = 'Standard',
    VarArgs = 'VarArgs',
    Any = 'Any',
    HasThis = 'HasThis',
    ExplicitThis = 'ExplicitThis'
}

export function CallingConventionsFromJSON(json: any): CallingConventions {
    return CallingConventionsFromJSONTyped(json, false);
}

export function CallingConventionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CallingConventions {
    return json as CallingConventions;
}

export function CallingConventionsToJSON(value?: CallingConventions | null): any {
    return value as any;
}

