import MaskedInput from 'react-text-mask';
import * as React from 'react';

export function TimeMaskInput(props: any) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, ':', /\d/, /\d/, ':', /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}