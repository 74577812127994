/* tslint:disable */
/* eslint-disable */
/**
 * sDSRelay API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ExternalVariableOperation {
    Insert = 'Insert',
    Maintain = 'Maintain',
    Update = 'Update'
}

export function ExternalVariableOperationFromJSON(json: any): ExternalVariableOperation {
    return ExternalVariableOperationFromJSONTyped(json, false);
}

export function ExternalVariableOperationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalVariableOperation {
    return json as ExternalVariableOperation;
}

export function ExternalVariableOperationToJSON(value?: ExternalVariableOperation | null): any {
    return value as any;
}

