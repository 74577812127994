import {action, makeObservable} from 'mobx';

export class ConfirmDeleteDialogState {
    open: boolean = false;
    name: string;
    callback: () => void;
    
    constructor() {
        makeObservable(this);
    }

    @action close() {
        this.open = false;
        this.name = '';
        this.callback = () => {};
    }
}