/* tslint:disable */
/* eslint-disable */
/**
 * sDSRelay API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum MemberTypes {
    Constructor = 'Constructor',
    Event = 'Event',
    Field = 'Field',
    Method = 'Method',
    Property = 'Property',
    TypeInfo = 'TypeInfo',
    Custom = 'Custom',
    NestedType = 'NestedType',
    All = 'All'
}

export function MemberTypesFromJSON(json: any): MemberTypes {
    return MemberTypesFromJSONTyped(json, false);
}

export function MemberTypesFromJSONTyped(json: any, ignoreDiscriminator: boolean): MemberTypes {
    return json as MemberTypes;
}

export function MemberTypesToJSON(value?: MemberTypes | null): any {
    return value as any;
}

