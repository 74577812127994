import {action, makeObservable, observable, runInAction} from 'mobx';
import {UtilsApi} from '../../../api';

export class WorkerStatusState {
    @observable items: any;
    
    constructor(private _utilsService: UtilsApi) {
        makeObservable(this);
        runInAction(() => _utilsService.apiUtilsWorkerStatusGet().then(r => this.setItems(JSON.parse(r)))).then();
    }

    @action setItems(i: any) {
        this.items = i;
    }
}