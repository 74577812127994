import {Container} from 'inversify';
import "reflect-metadata";
import {HomeState} from './components/views/Home/HomeState';
import {InternalVariablesConfigurationState} from './components/views/VariablesConfiguration/InternalVariablesConfigurationState';
import {ExternalVariablesConfigurationState} from './components/views/VariablesConfiguration/ExternalVariablesConfigurationState';
import {CommonService} from './components/services/commonService';
import {UtilsApi} from './api';

const container = new Container();
container.bind<HomeState>(HomeState).toSelf().inSingletonScope();
container.bind<CommonService>(CommonService).toConstantValue(new CommonService(new UtilsApi()));
container.bind<InternalVariablesConfigurationState>(InternalVariablesConfigurationState).toSelf().inSingletonScope();
container.bind<ExternalVariablesConfigurationState>(ExternalVariablesConfigurationState).toSelf().inSingletonScope();
export default container;