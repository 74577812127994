import * as React from 'react';
import {observer} from 'mobx-react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import {Button} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import {ConfirmDeleteDialogState} from './ConfirmDeleteDialogState';

export class ConfirmDeleteDialogProps{
    state : ConfirmDeleteDialogState;
}

export const ConfirmDeleteDialog: React.FC<ConfirmDeleteDialogProps> = observer(({state}) => {
    return (
        <Dialog
            open={state.open}
            onClose={() => state.close()}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Do you realy want to delete {state.name}?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    state.callback();
                    state.close();
                }} color="primary">
                    Yes
                </Button>
                <Button onClick={() => state.close()} color="primary">
                    No
                </Button>
            </DialogActions>
        </Dialog>
    )
})