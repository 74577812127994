/* tslint:disable */
/* eslint-disable */
/**
 * sDSRelay API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ParserType,
    ParserTypeFromJSON,
    ParserTypeFromJSONTyped,
    ParserTypeToJSON,
    VariableImportEditItem,
    VariableImportEditItemFromJSON,
    VariableImportEditItemFromJSONTyped,
    VariableImportEditItemToJSON,
    VariableImportListItem,
    VariableImportListItemFromJSON,
    VariableImportListItemFromJSONTyped,
    VariableImportListItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface VariableImportConfigEditItem
 */
export interface VariableImportConfigEditItem {
    /**
     * 
     * @type {Array<VariableImportEditItem>}
     * @memberof VariableImportConfigEditItem
     */
    variables?: Array<VariableImportEditItem> | null;
    /**
     * 
     * @type {Array<VariableImportListItem>}
     * @memberof VariableImportConfigEditItem
     */
    parsedVariables?: Array<VariableImportListItem> | null;
    /**
     * 
     * @type {number}
     * @memberof VariableImportConfigEditItem
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof VariableImportConfigEditItem
     */
    name?: string | null;
    /**
     * 
     * @type {ParserType}
     * @memberof VariableImportConfigEditItem
     */
    parserType?: ParserType;
    /**
     * 
     * @type {string}
     * @memberof VariableImportConfigEditItem
     */
    refreshCron?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VariableImportConfigEditItem
     */
    sourcePath?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VariableImportConfigEditItem
     */
    tableName?: string | null;
}

export function VariableImportConfigEditItemFromJSON(json: any): VariableImportConfigEditItem {
    return VariableImportConfigEditItemFromJSONTyped(json, false);
}

export function VariableImportConfigEditItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): VariableImportConfigEditItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'variables': !exists(json, 'variables') ? undefined : (json['variables'] === null ? null : (json['variables'] as Array<any>).map(VariableImportEditItemFromJSON)),
        'parsedVariables': !exists(json, 'parsedVariables') ? undefined : (json['parsedVariables'] === null ? null : (json['parsedVariables'] as Array<any>).map(VariableImportListItemFromJSON)),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'parserType': !exists(json, 'parserType') ? undefined : ParserTypeFromJSON(json['parserType']),
        'refreshCron': !exists(json, 'refreshCron') ? undefined : json['refreshCron'],
        'sourcePath': !exists(json, 'sourcePath') ? undefined : json['sourcePath'],
        'tableName': !exists(json, 'tableName') ? undefined : json['tableName'],
    };
}

export function VariableImportConfigEditItemToJSON(value?: VariableImportConfigEditItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'variables': value.variables === undefined ? undefined : (value.variables === null ? null : (value.variables as Array<any>).map(VariableImportEditItemToJSON)),
        'parsedVariables': value.parsedVariables === undefined ? undefined : (value.parsedVariables === null ? null : (value.parsedVariables as Array<any>).map(VariableImportListItemToJSON)),
        'id': value.id,
        'name': value.name,
        'parserType': ParserTypeToJSON(value.parserType),
        'refreshCron': value.refreshCron,
        'sourcePath': value.sourcePath,
        'tableName': value.tableName,
    };
}


