/* tslint:disable */
/* eslint-disable */
/**
 * sDSRelay API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ParserType,
    ParserTypeFromJSON,
    ParserTypeFromJSONTyped,
    ParserTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface VariableImportConfigEditItemFlat
 */
export interface VariableImportConfigEditItemFlat {
    /**
     * 
     * @type {number}
     * @memberof VariableImportConfigEditItemFlat
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof VariableImportConfigEditItemFlat
     */
    name?: string | null;
    /**
     * 
     * @type {ParserType}
     * @memberof VariableImportConfigEditItemFlat
     */
    parserType?: ParserType;
    /**
     * 
     * @type {string}
     * @memberof VariableImportConfigEditItemFlat
     */
    refreshCron?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VariableImportConfigEditItemFlat
     */
    sourcePath?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VariableImportConfigEditItemFlat
     */
    tableName?: string | null;
}

export function VariableImportConfigEditItemFlatFromJSON(json: any): VariableImportConfigEditItemFlat {
    return VariableImportConfigEditItemFlatFromJSONTyped(json, false);
}

export function VariableImportConfigEditItemFlatFromJSONTyped(json: any, ignoreDiscriminator: boolean): VariableImportConfigEditItemFlat {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'parserType': !exists(json, 'parserType') ? undefined : ParserTypeFromJSON(json['parserType']),
        'refreshCron': !exists(json, 'refreshCron') ? undefined : json['refreshCron'],
        'sourcePath': !exists(json, 'sourcePath') ? undefined : json['sourcePath'],
        'tableName': !exists(json, 'tableName') ? undefined : json['tableName'],
    };
}

export function VariableImportConfigEditItemFlatToJSON(value?: VariableImportConfigEditItemFlat | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'parserType': ParserTypeToJSON(value.parserType),
        'refreshCron': value.refreshCron,
        'sourcePath': value.sourcePath,
        'tableName': value.tableName,
    };
}


