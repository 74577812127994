import * as React from 'react';
import {EditInternalVariablesConfigurationState} from './EditInternalVariablesConfigurationState';
import {
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Fab, Paper,
    Table,
    TableBody,
    TableCell, TableHead,
    TableRow, TextField
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import {observer} from 'mobx-react';
import { TimeMaskInput } from '../Common/TimeMaskInput';
import {Link, NavLink} from 'react-router-dom';

interface EditInternalVariablesConfigurationProps {
    state: EditInternalVariablesConfigurationState;
}

export const EditInternalVariablesConfiguration: React.FC<EditInternalVariablesConfigurationProps> = observer(({state}) => {
    function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        if (typeof(state.data) === typeof(undefined)) return;
        
        e.preventDefault();

        state.save();
    }
    
    return (
        <Paper>
            <h1>Edit {state.title}</h1>
            <form method="post" onSubmit={e => handleSubmit(e)} id="form-edit-internal-variables">
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <TextField
                                    required
                                    label="Key"
                                    value={state.data && state.data.key.value}
                                    onChange={event => state.setKey(event.target.value)}
                                    margin="normal"
                                    error={state.data && state.data.key.errorText.length === 0 ? false : true}
                                    helperText={state.data && state.data.key.errorText}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    required
                                    select
                                    label="Type:"
                                    value={state.data && state.data.type.value}
                                    onChange={(event) => state.setType(event.target.value)}
                                    SelectProps={{
                                        native: true,
                                        MenuProps: {
                                        },
                                    }}
                                    InputProps={{
                                    }}
                                    margin="normal"
                                    variant="filled"
                                >
                                    <option />
                                    {state.internalVariableTypes && state.internalVariableTypes.map((option: any) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </TextField>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <TextField
                                    required
                                    label="Sub-table name."
                                    value={state.data && state.data.subTableName.value}
                                    onChange={event => state.setSubTableName(event.target.value)}
                                    margin="normal"
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    required
                                    label="Default value."
                                    value={state.data && state.data.defaultValue.value}
                                    onChange={event => state.setDefaultValue(event.target.value)}
                                    margin="normal"
                                    error={state.data && state.data.defaultValue.errorText.length === 0 ? false : true}
                                    helperText={state.data && state.data.defaultValue.errorText}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <TextField
                                    label="Reset time"
                                    value={state.data && state.data.resetTime.value}
                                    onChange={event => state.setResetTime(event.target.value)}
                                    InputProps={{
                                        inputComponent: TimeMaskInput,
                                    }}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Operations:
                                <Paper>
                                    <div>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>External variable key</TableCell>
                                                    <TableCell>External variable operation</TableCell>
                                                    <TableCell>External variable value</TableCell>
                                                    <TableCell>Expression</TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {state.data && state.data.operations.map((row: any, index: number) => {
                                                    return (
                                                        <TableRow>
                                                            <TableCell>
                                                                <TextField
                                                                    required
                                                                    placeholder='*'
                                                                    value={row.externalVariableKey.value}
                                                                    onChange={event => state.setExternalVariableKey(index, event.target.value)}
                                                                    error={row.externalVariableKey && row.externalVariableKey.errorText.length === 0 ? false : true}
                                                                    helperText={row.externalVariableKey && row.externalVariableKey.errorText} />
                                                            </TableCell>
                                                            <TableCell>
                                                                <TextField
                                                                    required
                                                                    select
                                                                    placeholder='*'
                                                                    value={row.externalVariableOperation.value}
                                                                    onChange={event => state.setExternalVariableOperation(index, event.target.value)}
                                                                    SelectProps={{
                                                                        native: true,
                                                                        MenuProps: {
                                                                        },
                                                                    }}
                                                                    margin="normal"
                                                                    variant="standard"
                                                                    error={row.externalVariableOperation && row.externalVariableOperation.errorText.length === 0 ? false : true}
                                                                    helperText={row.externalVariableOperation && row.externalVariableOperation.errorText}
                                                                >
                                                                    <option />
                                                                    {state.externalVariableOperationTypes && state.externalVariableOperationTypes.map((option: any) => (
                                                                        <option key={option.value} value={option.value}>
                                                                            {option.label}
                                                                        </option>
                                                                    ))}
                                                                </TextField>
                                                            </TableCell>
                                                            <TableCell>
                                                                <TextField
                                                                    value={row.externalVariableValue.value}
                                                                    error={row.externalVariableValue && row.externalVariableValue.errorText.length === 0 ? false : true}
                                                                    helperText={row.externalVariableValue.errorText}
                                                                    onChange={event => state.setExternalVariableValue(index, event.target.value)} />
                                                            </TableCell>
                                                            <TableCell>
                                                                <TextField
                                                                    required
                                                                    placeholder='*'
                                                                    error={row.expression && row.expression.errorText.length === 0 ? false : true}
                                                                    helperText={row.expression.errorText}
                                                                    value={row.expression.value}
                                                                    onChange={event => state.setExpression(index, event.target.value)} />
                                                            </TableCell>
                                                            <TableCell colSpan={2}>
                                                                <Button
                                                                    onClick={() => state.deleteInternalVariableOperation(row)} >
                                                                    <DeleteIcon />
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>
                                    </div>
                                    <Fab color="primary" aria-label="Add">
                                        <AddIcon onClick={() => state.addInternalVariableOperation()} />
                                    </Fab>
                                </Paper>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <Button type="submit" form="form-edit-internal-variables" color="primary">
                    Save
                </Button>
                <Button onClick={() => {}} color="primary">
                    <NavLink className="text-dark text-decoration-none" to={"/internal-variables-configuration"}>Cancel</NavLink>
                </Button>
            </form>
        </Paper>
        // <Dialog
        //     maxWidth='md'
        //     open={state.open}
        //     onClose={() => state.close()}
        //     aria-labelledby="form-dialog-title"
        // >
        //     <DialogTitle id="form-dialog-title"></DialogTitle>
        //     <DialogContent>
        //         <DialogContentText>
        //         </DialogContentText>
        //        
        //     </DialogContent>
        //     <DialogActions>
        //       
        //     </DialogActions>
        // </Dialog>
    )
})