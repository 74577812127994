/* tslint:disable */
/* eslint-disable */
/**
 * sDSRelay API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    VariableImportConfigEditItem,
    VariableImportConfigEditItemFromJSON,
    VariableImportConfigEditItemToJSON,
    VariableImportConfigEditItemFlat,
    VariableImportConfigEditItemFlatFromJSON,
    VariableImportConfigEditItemFlatToJSON,
    VariablesImportConfigurationOut,
    VariablesImportConfigurationOutFromJSON,
    VariablesImportConfigurationOutToJSON,
} from '../models';

export interface ApiVariablesImportIdDeleteRequest {
    id: number;
}

export interface ApiVariablesImportIdDetailsGetRequest {
    id: number;
}

export interface ApiVariablesImportIdGetRequest {
    id: number;
}

export interface ApiVariablesImportIdPutRequest {
    id: number;
    variableImportConfigEditItem?: VariableImportConfigEditItem;
}

export interface ApiVariablesImportPostRequest {
    variableImportConfigEditItem?: VariableImportConfigEditItem;
}

/**
 * 
 */
export class VariablesImportApi extends runtime.BaseAPI {

    /**
     */
    async apiVariablesImportGetRaw(): Promise<runtime.ApiResponse<VariablesImportConfigurationOut>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/VariablesImport`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VariablesImportConfigurationOutFromJSON(jsonValue));
    }

    /**
     */
    async apiVariablesImportGet(): Promise<VariablesImportConfigurationOut> {
        const response = await this.apiVariablesImportGetRaw();
        return await response.value();
    }

    /**
     */
    async apiVariablesImportIdDeleteRaw(requestParameters: ApiVariablesImportIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiVariablesImportIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/VariablesImport/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiVariablesImportIdDelete(requestParameters: ApiVariablesImportIdDeleteRequest): Promise<void> {
        await this.apiVariablesImportIdDeleteRaw(requestParameters);
    }

    /**
     */
    async apiVariablesImportIdDetailsGetRaw(requestParameters: ApiVariablesImportIdDetailsGetRequest): Promise<runtime.ApiResponse<VariableImportConfigEditItem>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiVariablesImportIdDetailsGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/VariablesImport/{id}/details`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VariableImportConfigEditItemFromJSON(jsonValue));
    }

    /**
     */
    async apiVariablesImportIdDetailsGet(requestParameters: ApiVariablesImportIdDetailsGetRequest): Promise<VariableImportConfigEditItem> {
        const response = await this.apiVariablesImportIdDetailsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiVariablesImportIdGetRaw(requestParameters: ApiVariablesImportIdGetRequest): Promise<runtime.ApiResponse<VariableImportConfigEditItemFlat>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiVariablesImportIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/VariablesImport/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VariableImportConfigEditItemFlatFromJSON(jsonValue));
    }

    /**
     */
    async apiVariablesImportIdGet(requestParameters: ApiVariablesImportIdGetRequest): Promise<VariableImportConfigEditItemFlat> {
        const response = await this.apiVariablesImportIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiVariablesImportIdPutRaw(requestParameters: ApiVariablesImportIdPutRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiVariablesImportIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/VariablesImport/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: VariableImportConfigEditItemToJSON(requestParameters.variableImportConfigEditItem),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiVariablesImportIdPut(requestParameters: ApiVariablesImportIdPutRequest): Promise<number> {
        const response = await this.apiVariablesImportIdPutRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiVariablesImportPostRaw(requestParameters: ApiVariablesImportPostRequest): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/VariablesImport`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VariableImportConfigEditItemToJSON(requestParameters.variableImportConfigEditItem),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiVariablesImportPost(requestParameters: ApiVariablesImportPostRequest): Promise<number> {
        const response = await this.apiVariablesImportPostRaw(requestParameters);
        return await response.value();
    }

}
