import {observable, action, computed, makeObservable, runInAction} from 'mobx';
import {
    ExtractedError,
    IErrorText,
    InputContent,
    InternalVariableEditItem
} from './EditInternalVariablesConfigurationState';
import {
    ExternalVariableTypesConfigurationApi,
    ApiExternalVariableTypesConfigurationsPostRequest,
    ApiExternalVariableTypesConfigurationsIdPutRequest, ExternalVariableTypeConfigurationIn
} from '../../../api';
import Swal from 'sweetalert2';

export class ExternalVariableConfigurationEditItem{
    @observable id?: number;
    @observable typeName: InputContent<string>;
    @observable matchingPattern: InputContent<string>;
    @observable defaultResetTime: InputContent<string>;
    @observable defaultLifetime: InputContent<string>;
    @observable defaultValue: InputContent<string>;
    @observable matchOrder?: number;
}

export class EditExternalVariablesConfigurationState {
    @observable open: boolean;
    @observable data?: ExternalVariableConfigurationEditItem;
    @computed get title() {
        return this.data?.typeName?.value
    }
    
    @observable id?: number;

    constructor(private externalVariableTypesConfigurationService: ExternalVariableTypesConfigurationApi, id: number | undefined = undefined) {
        makeObservable(this);
        this.id = id;
        this.createEmptyData();
        this.loadDataIfIdProvided();
    }

    @action setTypeName(value: string) {
        this.data!.typeName.value = value;
    }

    @action setMatchingPattern(value: string) {
        this.data!.matchingPattern.value = value;
    }

    @action setDefaultResetTime(value: string) {
        this.data!.defaultResetTime.value = value;
    }

    @action setDefaultLifetime(value: string) {
        this.data!.defaultLifetime.value = value;
    }

    @action setDefaultValue(value: string) {
        this.data!.defaultValue.value = value;
    }

    save() {
        let data = this.data!;
        if (typeof(data.id) === typeof(undefined)) {
            this.externalVariableTypesConfigurationService.apiExternalVariableTypesConfigurationsPost(new class implements ApiExternalVariableTypesConfigurationsPostRequest {
                externalVariableTypeConfigurationIn: ExternalVariableTypeConfigurationIn = new class implements ExternalVariableTypeConfigurationIn{
                    typeName?: string = data?.typeName?.value ?? undefined;
                    matchingPattern?: string = data?.matchingPattern?.value ?? undefined;
                    defaultResetTime?: string = data?.defaultResetTime?.value ?? undefined;
                    defaultLifetime?: string = data?.defaultLifetime?.value ?? undefined;
                    defaultValue?: string = data?.defaultValue?.value ?? undefined;
                    matchOrder?: number = data?.matchOrder ?? undefined;
            }
            }).then(r => this.backToList())
                .catch(r => this.handleError(r));
        }
        else{
            this.externalVariableTypesConfigurationService.apiExternalVariableTypesConfigurationsIdPut(new class implements ApiExternalVariableTypesConfigurationsIdPutRequest {
                externalVariableTypeConfigurationIn: ExternalVariableTypeConfigurationIn = new class implements ExternalVariableTypeConfigurationIn {
                    typeName?: string = data?.typeName?.value ?? undefined;
                    matchingPattern?: string = data?.matchingPattern?.value ?? undefined;
                    defaultResetTime?: string = data?.defaultResetTime?.value ?? undefined;
                    defaultLifetime?: string = data?.defaultLifetime?.value ?? undefined;
                    defaultValue?: string = data?.defaultValue?.value ?? undefined;
                    matchOrder?: number = data?.matchOrder ?? undefined;
                    id: number;
                };
                id: number = data.id!;
            }).then(r => this.backToList())
                .catch(r => this.handleError(r));
        }
    }

    private createEmptyData() {
        this.data = new ExternalVariableConfigurationEditItem();
        this.data.id = undefined;
        this.data.defaultValue = new InputContent('');
        this.data.typeName = new InputContent('');
        this.data.matchingPattern = new InputContent('');
        this.data.defaultLifetime = new InputContent('');
        this.data.defaultResetTime = new InputContent('');
    }

    @action private parseErrors(errors: any[]) {
        let extractedErrors = this.extractErrors(errors)

        if (this.data instanceof ExternalVariableConfigurationEditItem) {
            extractedErrors.forEach(e => {
                // @ts-ignore
                let property = this.data[e.key.toLowerCase() as keyof InternalVariableEditItem] as IErrorText;
                property.setErrorText(e.error);
            })
        }
    }

    private extractErrors(errors: any): ExtractedError[]  {
        return Object.keys(errors).map(key => new ExtractedError(key, errors[key]));
    }

    @action loadDataIfIdProvided() {
        if (typeof(this.id) !== typeof(undefined))
        {
            this.createEmptyData();
            this.FillData(this.id!);
        }
        else{
            this.createEmptyData();
        }
    }

    private FillData(id: number) {
        this.externalVariableTypesConfigurationService.apiExternalVariableTypesConfigurationsIdGet({id})
            .then(configuration => {
                runInAction(() => {
                    if(!this.data) this.data = new ExternalVariableConfigurationEditItem();
                    this.setId(configuration.id);
                    this.setTypeName(configuration.typeName || '');
                    this.setDefaultResetTime(configuration.defaultResetTime || '');
                    this.setDefaultLifetime(configuration.defaultLifetime || '');
                    this.setDefaultValue(configuration.defaultValue || '');
                    this.setMatchingPattern(configuration.matchingPattern || '');
                    this.setMatchOrder(configuration.matchOrder);
                })
            })
    }

    @action setId(id: number | undefined) {
        this.data!.id = id;
    }

    @action setMatchOrder(matchOrder: number | undefined) {
        this.data!.matchOrder = matchOrder;
    }

    private backToList() {
        window.location.replace("/external-variables-configuration")
    }

    private handleError(r: any) {
        if (r.status === 400)
        {
            Swal.fire({
                title: 'Error',
                confirmButtonText: 'Ok',
                icon: 'error'
            }).then(() => r.json().then((j: any) => {if (j.errors) this.parseErrors(j.errors)}));
        }
    }
}