import {action, makeObservable, observable} from 'mobx';
import {VariableValuesApi} from '../../../api';

export class InternalVariableListItem{
    key: string;
    value: string;
    tableName: string;
}

export class InternalVariableValuesState {
    @observable items: InternalVariableListItem[] = [];
    private isFetching: boolean;
    
    constructor(private _variableValuesService: VariableValuesApi) {
        makeObservable(this);
        this.loadData();
    }

    @action loadData() {
        if (this.isFetching)
            return;

        this.isFetching = true;
        this._variableValuesService.apiVariableValuesInternalGet().then(r => this.items = JSON.parse(r)).finally(() => this.isFetching = false);
    }
}