import * as React from 'react';
import {observer} from 'mobx-react';
import {Button, Card, Paper, Table, TableBody, TableCell, TableRow} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import {ChangePasswordState} from './ChangePasswordState';
import {Form} from 'react-bootstrap';

export class ChangePasswordProps{
    state : ChangePasswordState;
}

export const ChangePassword: React.FC<ChangePasswordProps> = observer(({state}) => {
    return (
        <div>
            <Card style={{maxWidth: '50%', marginLeft: 'auto', marginRight: 'auto'}}>
                <Form onSubmit={async x => await state.submit(x)}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <TextField
                                        label="Current password"
                                        type="password"
                                        autoComplete="current-password"
                                        variant="filled"
                                        value={state.currentPassword}
                                        onChange={event => state.setCurrentPassword(event.target.value)}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        label="New password"
                                        type="password"
                                        autoComplete="new-password"
                                        variant="filled"
                                        value={state.newPassword}
                                        onChange={event => state.setNewPassword(event.target.value)}
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <Button type="submit" style={{float: 'right'}}>Change</Button>
                </Form>
            </Card>
        </div>
    )
})