import * as React from 'react';
import {observer} from 'mobx-react';
import {AccountListViewState} from './AccountListViewState';
import Paper from '@material-ui/core/Paper';
import {Button, Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import {NavLink} from 'react-router-dom';
import {AccountEditState} from './AccountEditState';
import TextField from '@material-ui/core/TextField';

export class AccountEditProps{
    state : AccountEditState;
}

export const AccountEdit: React.FC<AccountEditProps> = observer(({state}) => {
    return (
        <Paper>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <TextField
                                required
                                label="Name"
                                value={state.data.name && state.data.name.value}
                                onChange={event => state.setName(event.target.value)}
                                margin="normal"
                                error={state.data.name.errorText.length === 0 ? false : true}
                                helperText={state.data.name.errorText}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <TextField
                                required
                                label="Email"
                                value={state.data.email && state.data.email.value}
                                onChange={event => state.setEmail(event.target.value)}
                                margin="normal"
                                error={state.data.email.errorText.length === 0 ? false : true}
                                helperText={state.data.email.errorText}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <TextField
                                label="Password"
                                type="password"
                                autoComplete="current-password"
                                variant="filled"
                                value={state.data.password && state.data.password.value}
                                onChange={event => state.setPassword(event.target.value)}
                                error={state.data.password.errorText.length === 0 ? false : true}
                                helperText={state.data.password.errorText}
                            />
                        </TableCell>
                    </TableRow>
                    
                </TableBody>
            </Table>
            <Button onClick={() => state.save()} color="primary">
                Save
            </Button>
            <Button onClick={() => {}} color="primary">
                <NavLink className="text-dark text-decoration-none" to={"/users"}>Cancel</NavLink>
            </Button>
        </Paper>
    )
})