import {inject, injectable} from 'inversify';
import {UtilsApi} from '../../api';

@injectable()
export class CommonService{
    constructor(private utilsService: UtilsApi) {
    }
    
    reloadApp() {
        this.utilsService.apiUtilsRefreshVariablesConfigurationPost().then(r => {});
    }
}