import * as React from 'react';
import {observer} from 'mobx-react';
import {Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import {WorkerStatusState} from './WorkerStatusState';

interface WorkerStatusProps{
    state: WorkerStatusState;
}

export const WorkerStatus: React.FC<WorkerStatusProps> = observer(({state}) => (
    <Table>
        <TableHead>
            <TableRow><TableCell colSpan={2}>Server time: {state.items?.currentTime}</TableCell></TableRow>
            <TableRow><TableCell colSpan={2}>Jobs:</TableCell></TableRow>
        </TableHead>
        <TableBody>
            {state.items && state.items?.runningJobs?.map((item: any) => {
                    return (
                        <TableRow>
                            <TableCell padding="default">{item.name}</TableCell>
                        </TableRow>
                    );
                }
            )}
        </TableBody>
    </Table>

))