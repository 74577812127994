import {action, computed, makeObservable, observable, runInAction} from 'mobx';
import {InputContent} from '../../VariablesConfiguration/EditInternalVariablesConfigurationState';

import {
    ApiVariablesImportIdGetRequest,
    ApiVariablesImportIdPutRequest,
    ApiVariablesImportPostRequest, ParserType, VariableImportConfigEditItem, VariableImportEditItem,
    VariableImportListItem,
    VariablesImportApi
} from '../../../../api';
import Swal from 'sweetalert2';
import * as querystring from 'query-string';

export class ImportedVariable {
    key: string;
}

export class VariableImportedEditItem {
    id?: number;
    name: InputContent<string>;
    parserType: InputContent<ParserType | undefined>;
    refreshCron: InputContent<string>;
    sourcePath: InputContent<string>;
    tableName: InputContent<string>;
    @observable variables: ImportedVariable[] = [];
    @observable parsedVariables: VariableImportListItem[] = [];
    
    constructor() {
        makeObservable(this);
    }
}

export class VariableImportWizardState {
    @observable step: number = 0;
    @observable editItem: VariableImportedEditItem;
    @computed get refreshMinutes() : number{
        let minutes = this.editItem.refreshCron.value.split(' ')[1];
        return Number.parseInt(minutes.substring(2));
    }
    parsers: ParserType[] = Object.keys(ParserType).map((key) => ParserType[key as keyof typeof ParserType]);

    constructor(private variablesImportService: VariablesImportApi) {
        makeObservable(this);
        let id = (querystring.parse(window.location.search.substring(1)) as any).id;

        this.editItem = this.getEmptyItem(id);

        if (typeof id !== typeof undefined) this.loadEditItemFlat(id!);
    }

    @action loadEditItemDetailed(id: number, callback: () => void = () => {}) {
        this.variablesImportService.apiVariablesImportIdDetailsGet({id})
            .then(r => {
                runInAction(() => {
                    this.editItem.id = r.id;
                    this.setName(r.name ?? '');
                    if (r.parserType) this.setParserType(r.parserType);
                    this.setRefreshCron(r.refreshCron ?? '');
                    this.setSourcePath(r.sourcePath ?? '')
                    this.setTableName(r.tableName ?? '')
                    this.setVariables(r.variables || undefined);
                    this.setParsedVariable(r.parsedVariables || undefined);
                })
            }).then(() => callback());
    }

    @action loadEditItemFlat(id: number, callback: () => void = () => {}) {
        this.variablesImportService.apiVariablesImportIdGet(new class implements ApiVariablesImportIdGetRequest {
            id: number = id;
        })
            .then(r => {
                runInAction(() => {
                    this.editItem.id = r.id;
                    this.setName(r.name ?? '');
                    if (r.parserType) this.setParserType(r.parserType);
                    this.setRefreshCron(r.refreshCron ?? '');
                    this.setSourcePath(r.sourcePath ?? '')
                    this.setTableName(r.tableName ?? '')
                })
            }).then(() => callback());
    }
    
    getEmptyItem(id: number | undefined): VariableImportedEditItem {
        let item = new VariableImportedEditItem();
        item.id = id; 
        item.name = new InputContent<string>("");
        item.parserType = new InputContent<ParserType | undefined>(undefined);
        item.refreshCron = new InputContent<string>("* 0/1 * * * ? *");
        item.sourcePath = new InputContent<string>("");
        item.tableName = new InputContent<string>("");
        return item;
    }

    @action nextStep() {
        if (this.step + 1 == 1 && typeof this.editItem.id === typeof undefined){
            const item = this.editItem;
            this.variablesImportService.apiVariablesImportPost(new class implements ApiVariablesImportPostRequest {
                variableImportConfigEditItem: VariableImportConfigEditItem = new class implements VariableImportConfigEditItem {
                    id?: number = undefined;
                    name?: string = item.name.value;
                    parserType?: ParserType = item.parserType.value;
                    refreshCron?: string = item.refreshCron.value;
                    sourcePath: string = item.sourcePath.value;
                };
            }).then((r) => {
                this.loadEditItemFlat(r, () => this.step += 1)
            }).catch(e => {
                if (e.status === 400){
                    Swal.fire({
                        title: 'Error',
                        confirmButtonText: 'Ok',
                        icon: 'error'
                    }).then(() => e.json().then((j: any) => {
                        if (j.errors.SourcePath) this.editItem.sourcePath.errorText = j.errors.SourcePath[0]
                    }));
                }
            });
        }
        else if (this.step + 1 == 1){
            const item = this.editItem;
            this.variablesImportService.apiVariablesImportIdPut(new class implements ApiVariablesImportIdPutRequest {
                id: number = item.id!;
                variableImportConfigEditItem: VariableImportConfigEditItem = new class implements VariableImportConfigEditItem {
                    id?: number = item.id;
                    name?: string = item.name.value;
                    parserType?: ParserType = (item.parserType.value);
                    refreshCron?: string = item.refreshCron.value
                    sourcePath: string = item.sourcePath.value;

            }}).then((r) => {
                this.loadEditItemDetailed(r, () => this.step += 1)
            })
                .catch(e => {
                    if (e.status === 400){
                        const errors = JSON.parse(e.response).errors;
                        if (errors.sourcePath) this.editItem.sourcePath.errorText = errors.sourcePath[0];
                    }
                });
        }
    }

    isSelected(key: string) {
        return this.editItem.variables.findIndex(e => e.key == key) !== -1;
    }

    @action previousStep() {
        if (this.step - 1 > 0)
            this.step -= 1
        else this.step = 0;
    }

    @action setName(value: string) {
        this.editItem.name.value = value;
    }

    @action setParserType(value:  ParserType) {
       this.editItem.parserType.value = value as (ParserType | undefined);
    }

    @action setRefreshCron(value: string) {
        this.editItem.refreshCron.value = value;
    }

    @action setSourcePath(value: string) {
        this.editItem.sourcePath.value = value;
        this.editItem.sourcePath.errorText = '';
    }

    @action setTableName(value: string){
        this.editItem.tableName.value = value;
    }
    
    @computed get allRequiredFieldsFilled() : boolean {
        return !!(this.editItem.name.value && this.editItem.parserType.value && this.editItem.refreshCron.value && this.editItem.sourcePath.value);
    }

    @action setIsFetchable(index: number, value: boolean) {
        let selectedItem = this.editItem.variables.find(e => e.key == this.editItem.parsedVariables[index].key);
        if (typeof selectedItem !== typeof undefined){
            this.editItem.variables.splice(this.editItem.variables.indexOf(selectedItem!), 1);
        }
        else{
            let v = new ImportedVariable();
            v.key = this.editItem.parsedVariables[index].key!;
            this.editItem.variables.push(v);
        }
    }

    @action save() {
        const editItem = this.editItem;
        this.variablesImportService.apiVariablesImportIdPut(new class implements ApiVariablesImportIdPutRequest {
            id: number = editItem.id!;
            variableImportConfigEditItem: VariableImportConfigEditItem = new class implements VariableImportConfigEditItem {
                id: number = editItem.id!;
                name: string = editItem.name.value;
                parserType: ParserType = editItem.parserType.value!;
                refreshCron: string = editItem.refreshCron.value;
                sourcePath: string = editItem.sourcePath.value;
                tableName: string = editItem.tableName.value;
                variables: Array<VariableImportEditItem> = editItem.variables.map(v => {
                    let x = new class implements VariableImportEditItem{
                        variableKey: string = v.key;
                    };

                    return x;
                });
        }}).then(() => window.location.replace("/variable-import-configurations"));
}
    
    @action setVariables(r: Array<VariableImportEditItem> | undefined) {
        this.editItem.variables = r?.map(v => {
            let variable = new ImportedVariable();
            variable.key = v.variableKey!;
            return variable;
        }) ?? [];
    }

    @action setParsedVariable(r: Array<VariableImportListItem> | undefined) {
        this.editItem.parsedVariables = r?.map(v => {
            return  new class implements VariableImportListItem {
                key: string = v!.key!;
                description: string = v!.description!;
            }
        }) ?? [];
    }

    @action setRefreshMinutes(value: string) {
        let cronParts = this.editItem.refreshCron.value.split(' ');
        cronParts[1] = "0/" + value;
        
        this.editItem.refreshCron.value = cronParts.join(" ");
    }
}