/* tslint:disable */
/* eslint-disable */
/**
 * sDSRelay API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum MethodImplAttributes {
    Managed = 'Managed',
    Native = 'Native',
    Optil = 'OPTIL',
    CodeTypeMask = 'CodeTypeMask',
    Unmanaged = 'Unmanaged',
    NoInlining = 'NoInlining',
    ForwardRef = 'ForwardRef',
    Synchronized = 'Synchronized',
    NoOptimization = 'NoOptimization',
    PreserveSig = 'PreserveSig',
    AggressiveInlining = 'AggressiveInlining',
    AggressiveOptimization = 'AggressiveOptimization',
    InternalCall = 'InternalCall',
    MaxMethodImplVal = 'MaxMethodImplVal'
}

export function MethodImplAttributesFromJSON(json: any): MethodImplAttributes {
    return MethodImplAttributesFromJSONTyped(json, false);
}

export function MethodImplAttributesFromJSONTyped(json: any, ignoreDiscriminator: boolean): MethodImplAttributes {
    return json as MethodImplAttributes;
}

export function MethodImplAttributesToJSON(value?: MethodImplAttributes | null): any {
    return value as any;
}

