import {Button, Paper, Table, TableBody, TableCell, TableRow} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import {observer} from 'mobx-react';
import { EditExternalVariablesConfigurationState } from './EditExternalVariablesConfigurationState';
import {TimeMaskInput} from '../Common/TimeMaskInput';
import {NavLink} from 'react-router-dom';

interface EditExternalVariablesConfigurationProps {
    state: EditExternalVariablesConfigurationState;
}

export const EditExternalVariablesConfiguration: React.FC<EditExternalVariablesConfigurationProps> = observer(({state}) => {
    return (
        <Paper>
            <h1>Edit {state.title}</h1>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <TextField
                                required
                                label="Type name"
                                value={state.data && state.data.typeName.value}
                                onChange={event => state.setTypeName(event.target.value)}
                                margin="normal"
                                error={state.data && state.data.typeName.errorText.length === 0 ? false : true}
                                helperText={state.data && state.data.typeName.errorText}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <TextField
                                required
                                label="Type matching pattern"
                                value={state.data && state.data.matchingPattern.value}
                                onChange={event => state.setMatchingPattern(event.target.value)}
                                margin="normal"
                                error={state.data && state.data.matchingPattern.errorText.length === 0 ? false : true}
                                helperText={state.data && state.data.matchingPattern.errorText}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <TextField
                                label="Reset time"
                                value={state.data && state.data.defaultResetTime.value}
                                onChange={event => state.setDefaultResetTime(event.target.value)}
                                InputProps={{
                                    inputComponent: TimeMaskInput,
                                }}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <TextField
                                label="Life time"
                                value={state.data && state.data.defaultLifetime.value}
                                onChange={event => state.setDefaultLifetime(event.target.value)}
                                InputProps={{
                                    inputComponent: TimeMaskInput,
                                }}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <TextField
                                required
                                label="Default value."
                                value={state.data && state.data.defaultValue.value}
                                onChange={event => state.setDefaultValue(event.target.value)}
                                margin="normal"
                                error={state.data && state.data.defaultValue.errorText.length === 0 ? false : true}
                                helperText={state.data && state.data.defaultValue.errorText}
                            />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <Button onClick={() => state.save()} color="primary">
                Save
            </Button>
            <Button onClick={() => {}} color="primary">
                <NavLink className="text-dark text-decoration-none" to={"/external-variables-configuration"}>Cancel</NavLink>
            </Button>
        </Paper>
    )
})
