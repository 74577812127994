import * as React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'popper.js/dist/popper.min.js';
import 'jquery/dist/jquery.min.js'
import './style.scss';
import {observer, Provider} from 'mobx-react';
import {mainStore} from './components/mainStore';
import {Routes} from './routing/Routes';

export class AppProps{
    
}

export class AuthenticatedRoutesProps{

}

export const AuthenticatedRoutes: React.FC<AuthenticatedRoutesProps> = (observer(() => {
    return (<Routes state={mainStore}/>)
}));

export const App: React.FC<AppProps> = (observer(() => {
        return (
            <Provider mainStore={mainStore}>
                <AuthenticatedRoutes />
            </Provider>
        );
    }
))
