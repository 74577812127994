import * as React from 'react';
import {observer} from 'mobx-react';
import {VariableImportConfigurationState} from './VariableImportConfigurationState';
import Paper from '@material-ui/core/Paper';
import {Button, Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {ConfirmDeleteDialog} from '../Common/ConfirmDeleteDialog';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import {NavLink} from 'react-router-dom';

interface VariableImportConfigurationProps {
    state: VariableImportConfigurationState;
}

export const VariableImportConfigurationList: React.FC<VariableImportConfigurationProps> = observer(({state}) => {
    return (
        <div>
            <Paper>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Import name</TableCell>
                            <TableCell>Parser</TableCell>
                            <TableCell>Refresh cron</TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            state.importConfigurations?.map(row => {
                                return (
                                    <TableRow key={row.id}>
                                        <TableCell>
                                            <p>{row.name}</p>
                                        </TableCell>
                                        <TableCell>
                                            <p>{row.parserType}</p>
                                        </TableCell>
                                        <TableCell>
                                            <p>{row.refreshCron}</p>
                                        </TableCell>
                                        <TableCell colSpan={2}>
                                            <Button>
                                                <NavLink to={"/variable-import-configurations/edit?id="+row.id}><EditIcon style={{"color": "black"}} /></NavLink>
                                            </Button>
                                            <Button
                                                onClick={() => {state.delete(row.id!)}} >
                                                <DeleteIcon />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
                <ConfirmDeleteDialog state = {state.confirmDeleteDialogState} />
            </Paper>
            <Fab color="primary" aria-label="Add" style={{float: 'right'}}>
                <NavLink to="/variable-import-configurations/edit" ><AddIcon style={{"color": "white"}} /></NavLink>
            </Fab>
        </div>
    )})