import {action, makeObservable, observable} from 'mobx';

export class LayoutState {
    @observable isDrawerOpen: boolean = false;
    @observable isCurrentValuesOpen: boolean = false;

    constructor() {
        makeObservable(this);
    }
    
    @action openDrawer() {
        this.isDrawerOpen = true;
    }

    @action closeDrawer() {
        this.isDrawerOpen = false;
        this.isCurrentValuesOpen = false;
    }

    @action toggleCurrentValue() {
        this.isCurrentValuesOpen = !this.isCurrentValuesOpen;
        this.isDrawerOpen = this.isCurrentValuesOpen;
    }
}