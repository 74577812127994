import {VariablesStatus} from '../VariablesStatus/VariablesStatus';
import * as React from 'react';
import {observer} from 'mobx-react';
import {useEffect} from 'react';
import {InternalVariableListItem, InternalVariableValuesState} from './InternalVariableValuesState';
import {Paper, Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import {runInAction} from 'mobx';

interface InternalVariableValuesProps {
    state: InternalVariableValuesState; 
}

export const InternalVariableValues: React.FC<InternalVariableValuesProps> = observer(({state}) =>
{
    useEffect(() => {
        const interval = setInterval(() => {
            runInAction(() => state.loadData());
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div>
            <Paper>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Value</TableCell>
                            <TableCell>Table</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state.items && state.items.map((item: InternalVariableListItem) => {
                                return (
                                    <TableRow id={'internal-variable-value-' + item.key}>
                                        <TableCell padding="default">{item.key}</TableCell>
                                        <TableCell padding="default">{item.value}</TableCell>
                                        <TableCell padding="default">{item.tableName}</TableCell>
                                    </TableRow>
                                );
                            }
                        )}
                    </TableBody>
                </Table>
            </Paper>
        </div>
    );
})