//import 'tslib';

import React from 'react';
import ReactDOM from 'react-dom';

// @ts-ignore
import { App } from './app';

const root = document.getElementById('root');

ReactDOM.render(<App />, root);