import {action} from 'mobx';
import {AccountApi, ApiAccountLoginPostRequest} from '../../../api';
import Swal from 'sweetalert2';
import {mainStore} from '../../mainStore';

export class LoginState {
    userName: string;
    password: string;

    constructor(private accountService : AccountApi, private redirectUrl: string | undefined | null) {
    }
    
    @action setUserName(value: string) {
        this.userName = value;
    }

    @action submit(x: React.FormEvent<HTMLFormElement>) {
        x.preventDefault();
        const redirectUrl = this.redirectUrl || undefined;
        const userName = this.userName;
        const password = this.password;
        
        this.accountService.apiAccountLoginPost(new class implements ApiAccountLoginPostRequest {
                redirectUrl?: string = '';
                userName?: string = userName;
                password?: string = password;
        }
        ).then(() => {
            mainStore.getCurrentUser();
            if(typeof redirectUrl !== typeof undefined){
                window.location.replace(redirectUrl!)
            } 
        }).catch(() => {
            Swal.fire('Failed!',
                'Login failed!',
                'error');
        });
    }

    @action setPassword(value: string) {
        this.password = value;
    }
}