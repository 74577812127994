/* tslint:disable */
/* eslint-disable */
/**
 * sDSRelay API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum MethodAttributes {
    ReuseSlot = 'ReuseSlot',
    PrivateScopePrivate = 'PrivateScope, Private',
    PrivateScopeFamAndAssem = 'PrivateScope, FamANDAssem',
    PrivateScopeAssembly = 'PrivateScope, Assembly',
    PrivateScopeFamily = 'PrivateScope, Family',
    PrivateScopeFamOrAssem = 'PrivateScope, FamORAssem',
    PrivateScopePublic = 'PrivateScope, Public',
    PrivateScopeMemberAccessMask = 'PrivateScope, MemberAccessMask',
    PrivateScopeUnmanagedExport = 'PrivateScope, UnmanagedExport',
    PrivateScopeStatic = 'PrivateScope, Static',
    PrivateScopeFinal = 'PrivateScope, Final',
    PrivateScopeVirtual = 'PrivateScope, Virtual',
    PrivateScopeHideBySig = 'PrivateScope, HideBySig',
    PrivateScopeVtableLayoutMask = 'PrivateScope, VtableLayoutMask',
    PrivateScopeCheckAccessOnOverride = 'PrivateScope, CheckAccessOnOverride',
    PrivateScopeAbstract = 'PrivateScope, Abstract',
    PrivateScopeSpecialName = 'PrivateScope, SpecialName',
    PrivateScopeRtSpecialName = 'PrivateScope, RTSpecialName',
    PrivateScopePinvokeImpl = 'PrivateScope, PinvokeImpl',
    PrivateScopeHasSecurity = 'PrivateScope, HasSecurity',
    PrivateScopeRequireSecObject = 'PrivateScope, RequireSecObject',
    PrivateScopeReservedMask = 'PrivateScope, ReservedMask'
}

export function MethodAttributesFromJSON(json: any): MethodAttributes {
    return MethodAttributesFromJSONTyped(json, false);
}

export function MethodAttributesFromJSONTyped(json: any, ignoreDiscriminator: boolean): MethodAttributes {
    return json as MethodAttributes;
}

export function MethodAttributesToJSON(value?: MethodAttributes | null): any {
    return value as any;
}

