/* tslint:disable */
/* eslint-disable */
/**
 * sDSRelay API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InternalVariableConfigurationCreateIn,
    InternalVariableConfigurationCreateInFromJSON,
    InternalVariableConfigurationCreateInToJSON,
    InternalVariableConfigurationDto,
    InternalVariableConfigurationDtoFromJSON,
    InternalVariableConfigurationDtoToJSON,
    InternalVariableConfigurationOut,
    InternalVariableConfigurationOutFromJSON,
    InternalVariableConfigurationOutToJSON,
    InternalVariableConfigurationUpdateIn,
    InternalVariableConfigurationUpdateInFromJSON,
    InternalVariableConfigurationUpdateInToJSON,
    InternalVariableOperationConfigurationOut,
    InternalVariableOperationConfigurationOutFromJSON,
    InternalVariableOperationConfigurationOutToJSON,
    InternalVariableTypesResult,
    InternalVariableTypesResultFromJSON,
    InternalVariableTypesResultToJSON,
} from '../models';

export interface ApiInternalVariablesConfigurationsIdDeleteRequest {
    id: number;
}

export interface ApiInternalVariablesConfigurationsIdGetRequest {
    id: number;
}

export interface ApiInternalVariablesConfigurationsIdOperationsGetRequest {
    id: number;
}

export interface ApiInternalVariablesConfigurationsIdPutRequest {
    id: number;
    internalVariableConfigurationUpdateIn?: InternalVariableConfigurationUpdateIn;
}

export interface ApiInternalVariablesConfigurationsPostRequest {
    internalVariableConfigurationCreateIn?: InternalVariableConfigurationCreateIn;
}

/**
 * 
 */
export class InternalVariablesConfigurationApi extends runtime.BaseAPI {

    /**
     */
    async apiInternalVariablesConfigurationsGetRaw(): Promise<runtime.ApiResponse<Array<InternalVariableConfigurationOut>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/internal-variables-configurations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InternalVariableConfigurationOutFromJSON));
    }

    /**
     */
    async apiInternalVariablesConfigurationsGet(): Promise<Array<InternalVariableConfigurationOut>> {
        const response = await this.apiInternalVariablesConfigurationsGetRaw();
        return await response.value();
    }

    /**
     */
    async apiInternalVariablesConfigurationsIdDeleteRaw(requestParameters: ApiInternalVariablesConfigurationsIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiInternalVariablesConfigurationsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/internal-variables-configurations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiInternalVariablesConfigurationsIdDelete(requestParameters: ApiInternalVariablesConfigurationsIdDeleteRequest): Promise<void> {
        await this.apiInternalVariablesConfigurationsIdDeleteRaw(requestParameters);
    }

    /**
     */
    async apiInternalVariablesConfigurationsIdGetRaw(requestParameters: ApiInternalVariablesConfigurationsIdGetRequest): Promise<runtime.ApiResponse<InternalVariableConfigurationOut>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiInternalVariablesConfigurationsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/internal-variables-configurations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InternalVariableConfigurationOutFromJSON(jsonValue));
    }

    /**
     */
    async apiInternalVariablesConfigurationsIdGet(requestParameters: ApiInternalVariablesConfigurationsIdGetRequest): Promise<InternalVariableConfigurationOut> {
        const response = await this.apiInternalVariablesConfigurationsIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiInternalVariablesConfigurationsIdOperationsGetRaw(requestParameters: ApiInternalVariablesConfigurationsIdOperationsGetRequest): Promise<runtime.ApiResponse<Array<InternalVariableOperationConfigurationOut>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiInternalVariablesConfigurationsIdOperationsGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/internal-variables-configurations/{id}/operations`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InternalVariableOperationConfigurationOutFromJSON));
    }

    /**
     */
    async apiInternalVariablesConfigurationsIdOperationsGet(requestParameters: ApiInternalVariablesConfigurationsIdOperationsGetRequest): Promise<Array<InternalVariableOperationConfigurationOut>> {
        const response = await this.apiInternalVariablesConfigurationsIdOperationsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiInternalVariablesConfigurationsIdPutRaw(requestParameters: ApiInternalVariablesConfigurationsIdPutRequest): Promise<runtime.ApiResponse<InternalVariableConfigurationDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiInternalVariablesConfigurationsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/internal-variables-configurations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InternalVariableConfigurationUpdateInToJSON(requestParameters.internalVariableConfigurationUpdateIn),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InternalVariableConfigurationDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiInternalVariablesConfigurationsIdPut(requestParameters: ApiInternalVariablesConfigurationsIdPutRequest): Promise<InternalVariableConfigurationDto> {
        const response = await this.apiInternalVariablesConfigurationsIdPutRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiInternalVariablesConfigurationsInternalVariableTypesGetRaw(): Promise<runtime.ApiResponse<InternalVariableTypesResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/internal-variables-configurations/internal-variable-types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InternalVariableTypesResultFromJSON(jsonValue));
    }

    /**
     */
    async apiInternalVariablesConfigurationsInternalVariableTypesGet(): Promise<InternalVariableTypesResult> {
        const response = await this.apiInternalVariablesConfigurationsInternalVariableTypesGetRaw();
        return await response.value();
    }

    /**
     */
    async apiInternalVariablesConfigurationsPostRaw(requestParameters: ApiInternalVariablesConfigurationsPostRequest): Promise<runtime.ApiResponse<InternalVariableConfigurationDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/internal-variables-configurations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InternalVariableConfigurationCreateInToJSON(requestParameters.internalVariableConfigurationCreateIn),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InternalVariableConfigurationDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiInternalVariablesConfigurationsPost(requestParameters: ApiInternalVariablesConfigurationsPostRequest): Promise<InternalVariableConfigurationDto> {
        const response = await this.apiInternalVariablesConfigurationsPostRaw(requestParameters);
        return await response.value();
    }

}
