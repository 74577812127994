/* tslint:disable */
/* eslint-disable */
/**
 * sDSRelay API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    RedirectResult,
    RedirectResultFromJSON,
    RedirectResultToJSON,
} from '../models';

export interface ApiAccountAddUserPostRequest {
    userName?: string;
    password?: string;
}

export interface ApiAccountChangePasswordPostRequest {
    currentPassword?: string;
    newPassword?: string;
}

export interface ApiAccountLoginPostRequest {
    redirectUrl?: string;
    userName?: string;
    password?: string;
}

export interface ApiAccountLogoutPostRequest {
    redirectUrl?: string;
}

/**
 * 
 */
export class AccountApi extends runtime.BaseAPI {

    /**
     */
    async apiAccountAddUserPostRaw(requestParameters: ApiAccountAddUserPostRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.userName !== undefined) {
            formParams.append('UserName', requestParameters.userName as any);
        }

        if (requestParameters.password !== undefined) {
            formParams.append('Password', requestParameters.password as any);
        }

        const response = await this.request({
            path: `/api/Account/add-user`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiAccountAddUserPost(requestParameters: ApiAccountAddUserPostRequest): Promise<void> {
        await this.apiAccountAddUserPostRaw(requestParameters);
    }

    /**
     */
    async apiAccountChangePasswordPostRaw(requestParameters: ApiAccountChangePasswordPostRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.currentPassword !== undefined) {
            formParams.append('CurrentPassword', requestParameters.currentPassword as any);
        }

        if (requestParameters.newPassword !== undefined) {
            formParams.append('NewPassword', requestParameters.newPassword as any);
        }

        const response = await this.request({
            path: `/api/Account/change-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiAccountChangePasswordPost(requestParameters: ApiAccountChangePasswordPostRequest): Promise<void> {
        await this.apiAccountChangePasswordPostRaw(requestParameters);
    }

    /**
     */
    async apiAccountCurrentUserPostRaw(): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Account/current-user`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiAccountCurrentUserPost(): Promise<string> {
        const response = await this.apiAccountCurrentUserPostRaw();
        return await response.value();
    }

    /**
     */
    async apiAccountLoginPostRaw(requestParameters: ApiAccountLoginPostRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.redirectUrl !== undefined) {
            queryParameters['redirectUrl'] = requestParameters.redirectUrl;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.userName !== undefined) {
            formParams.append('UserName', requestParameters.userName as any);
        }

        if (requestParameters.password !== undefined) {
            formParams.append('Password', requestParameters.password as any);
        }

        const response = await this.request({
            path: `/api/Account/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiAccountLoginPost(requestParameters: ApiAccountLoginPostRequest): Promise<void> {
        await this.apiAccountLoginPostRaw(requestParameters);
    }

    /**
     */
    async apiAccountLogoutPostRaw(requestParameters: ApiAccountLogoutPostRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.redirectUrl !== undefined) {
            queryParameters['redirectUrl'] = requestParameters.redirectUrl;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Account/logout`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiAccountLogoutPost(requestParameters: ApiAccountLogoutPostRequest): Promise<void> {
        await this.apiAccountLogoutPostRaw(requestParameters);
    }

}
