import {action, computed, makeObservable, observable, runInAction} from 'mobx';
import {useParams} from 'react-router';
import {
    ApiInternalVariablesConfigurationsIdPutRequest,
    ApiInternalVariablesConfigurationsPostRequest,
    ExternalVariableOperation, ExternalVariableOperationTypesResult,
    ExternalVariableTypesConfigurationApi,
    InternalVariableConfigurationCreateIn,
    InternalVariableConfigurationUpdateIn,
    InternalVariableOperationConfigurationIn, InternalVariableOperationConfigurationOut,
    InternalVariablesConfigurationApi,
    InternalVariableType, InternalVariableTypesResult
} from '../../../api';
import Swal from 'sweetalert2';

const internalVariableOperationPropertyErrorRegex = "^internalVariableOperationConfigurations\\[\\d*\\]\\..*";
const internalVariableOperationsListErrorRegex = "^internalVariableOperationConfigurations\\[\\d*\\]$";
const indexRegex = "\\[\\d*\\]";

export interface IErrorText{
    errorText: string;
    setErrorText: (t: string) => void;
}

export class DropDownOptions<T>{
    value: T;
    label: string
    
    constructor(value: T, label: string) {
        this.value = value;
        this.label = label;
    }
}

export class ExtractedError{
    key: string;
    error: string;
    
    constructor(key:string, error:string) {
        this.key = key;
        this.error = error;
    }
}

export class ExternalOperationInputContent {
    @observable id?: number;
    @observable expression: InputContent<string>;
    @observable externalVariableKey: InputContent<string>;
    @observable externalVariableOperation: InputContent<ExternalVariableOperation>;
    @observable externalVariableValue: InputContent<string>;
    @observable operationIdentifier: InputContent<string>;

    constructor() {
        makeObservable(this);
    }
}

export class InputContent<T> implements IErrorText{
    @observable value: T;
    @observable errorText: string;

    constructor(value: T, errorText: string = '') {
        makeObservable(this);
        this.value = value;
        this.errorText = errorText;
    }
    
    @action setErrorText(t: string){
        this.errorText = t;
    }
}

export class InternalVariableEditItem{
    @observable id?: number;
    @observable key: InputContent<string>;
    @observable subTableName: InputContent<string>;
    @observable type: InputContent<InternalVariableType>;
    @observable defaultValue: InputContent<string>;
    @observable resetTime: InputContent<string>;
    @observable operations: ExternalOperationInputContent[];
    
    constructor() {
        makeObservable(this);
    }
}

export class EditInternalVariablesConfigurationState {
    @computed get title() {
        return this.data?.key?.value
    }
    @observable data?: InternalVariableEditItem;
    @observable internalVariableTypes: DropDownOptions<InternalVariableType>[];
    @observable externalVariableOperationTypes: DropDownOptions<ExternalVariableOperation>[];
    @observable id?: number;
    
    constructor(private internalVariablesConfigurationService: InternalVariablesConfigurationApi,
                private externalVariableTypesConfigurationService: ExternalVariableTypesConfigurationApi,
                id: number | undefined = undefined ) {
        makeObservable(this);
        this.id = id;
        this.getInternalVariableTypes();
        this.getExternalOperationVariableTypes();
        this.createEmptyData();
        runInAction(() => this.loadDataIfIdProvided());
    }

    save() {
        let data = this.data!;
        if (typeof(data.id) === typeof(undefined)) {
            this.internalVariablesConfigurationService.apiInternalVariablesConfigurationsPost(new class implements ApiInternalVariablesConfigurationsPostRequest {
                internalVariableConfigurationCreateIn: InternalVariableConfigurationCreateIn = new class implements InternalVariableConfigurationCreateIn {
                    defaultValue: string = data?.defaultValue?.value ?? undefined;
                    key: string = data?.key?.value ?? undefined;
                    resetTime: string = data?.resetTime?.value ?? undefined;
                    subTableName: string = data?.subTableName?.value ?? undefined;
                    type: InternalVariableType = data?.type?.value ?? undefined;
                    operations: Array<InternalVariableOperationConfigurationIn> = data?.operations?.map(x => {
                        return new class implements InternalVariableOperationConfigurationIn {
                            expression: string = x.expression?.value ? JSON.stringify(x.expression?.value) : '';
                            externalVariableKey: string = x.externalVariableKey?.value ?? undefined;
                            externalVariableOperation: ExternalVariableOperation = x.externalVariableOperation?.value ?? undefined;
                            externalVariableValue: string = x.externalVariableValue?.value ?? undefined;
                            id?: number = x.id;
                            operationIdentifier: string = x.operationIdentifier?.value ?? undefined;
                        }
                    }
            ) ?? undefined;
            }}).then(r => this.backToList())
                .catch(r => this.handleError(r));
        }
        else {
            this.internalVariablesConfigurationService.apiInternalVariablesConfigurationsIdPut(new class implements ApiInternalVariablesConfigurationsIdPutRequest {
                id: number = data.id!;
                internalVariableConfigurationUpdateIn: InternalVariableConfigurationUpdateIn = new class implements InternalVariableConfigurationUpdateIn {
                        id: number = data.id!;
                        defaultValue: string = data?.defaultValue?.value ?? undefined;
                        key: string = data?.key?.value ?? undefined;
                        resetTime: string = data?.resetTime?.value ?? undefined;
                        subTableName: string = data?.subTableName?.value ?? undefined;
                        type: InternalVariableType = data?.type?.value ?? undefined;
                        operations: Array<InternalVariableOperationConfigurationIn> = data?.operations?.map(x => {
                            return new class implements InternalVariableOperationConfigurationIn {
                                expression: string = x.expression?.value ?? undefined;
                                externalVariableKey: string = x.externalVariableKey?.value ?? undefined;
                                externalVariableOperation: ExternalVariableOperation = x.externalVariableOperation?.value ?? undefined;
                                externalVariableValue: string = x.externalVariableValue?.value ?? undefined;
                                id?: number = x.id;
                                operationIdentifier: string = x.operationIdentifier?.value ?? undefined;
                            }
                        }) ?? undefined;
                    };
            }).then(r => this.backToList())
                .catch(r => this.handleError(r));
        }
    }

    toLowerCase(str: string){
        return str.charAt(0).toLowerCase() + str.slice(1)
    }
    
    @action parseErrors(errors: any) {
        let extractedErrors = this.extractErrors(errors)

        if (this.data instanceof InternalVariableEditItem) {
            extractedErrors.forEach(e => {
                let itemName = this.toLowerCase(e.key);
                if (itemName.match(internalVariableOperationsListErrorRegex)) {
                    let indexMatch = itemName.match(indexRegex);
                    if (typeof indexMatch !== typeof undefined)
                    {
                        // @ts-ignore
                        let index = indexMatch[0].substr(1, indexMatch[0].length - 2);
                        // @ts-ignore
                        this.data.operations[Number.parseInt(index)].externalVariableKey.errorText = e.error;
                    }
                    
                    return;
                }
                if (itemName.match(internalVariableOperationPropertyErrorRegex)) {
                    let indexMatch = itemName.match(indexRegex);
                    if (typeof indexMatch !== typeof undefined)
                    {
                        // @ts-ignore
                        let index = indexMatch[0].substr(1, indexMatch[0].length - 2);
                        let propertyName = this.toLowerCase(itemName.split(".")[1]);
                        // @ts-ignore
                        this.data.operations[Number.parseInt(index)][propertyName as keyof IErrorText].errorText = e.error;
                    }
                    
                    return;
                }

                // @ts-ignore
                let property = this.data[e.key.toLowerCase() as keyof InternalVariableEditItem] as IErrorText;
                property.setErrorText(e.error);
            })
        }
    }

    extractErrors(errors: any): ExtractedError[] {
        return Object.keys(errors).map(key => new ExtractedError(key, errors[key]));
    }

    @action setKey(value: string) {
        this.data!.key.value = value;
    }
    @action setType(value: string) {
        this.data!.type.value = InternalVariableType[value as keyof typeof InternalVariableType];
    }
    @action
    getInternalVariableTypes() {
        this.internalVariablesConfigurationService.apiInternalVariablesConfigurationsInternalVariableTypesGet()
            .then(r => this.setInternalVariableTypes(r))
    }

    @action
    private getExternalOperationVariableTypes() {
        this.externalVariableTypesConfigurationService.apiExternalVariableTypesConfigurationsExternalVariableOperationTypesGet()
            .then(r => this.setExternalOperationVariableTypes(r));
    }

    @action setSubTableName(value: string): void {
        this.data!.subTableName.value = value;
    }

    @action setDefaultValue(value: string): void {
        this.data!.defaultValue.value = value;
    }

    @action setResetTime(value: string): void {
        this.data!.resetTime.value = value;
    }

    @action setExternalVariableKey(index: number, value: string): void {
        this.data!.operations[index].externalVariableKey.value = value;
    }

    @action setExternalVariableOperation(index: number, value: string): void {
        this.data!.operations[index].externalVariableOperation.value = ExternalVariableOperation[value as keyof typeof ExternalVariableOperation];
    }

    @action setExternalVariableValue(index: number, value: string) {
        this.data!.operations[index].externalVariableValue.value = value;
    }

    @action setExpression(index: number, value: string) {
        this.data!.operations[index].expression.value = value;
    }

    @action deleteInternalVariableOperation(row: any) {
        const index = this.data!.operations.indexOf(row, 0);
        if (index > -1) {
            this.data!.operations.splice(index, 1);
        }
    }

    @action addInternalVariableOperation(){
        this.data!.operations.push(this.crateEmptyOperation())
    }

    crateEmptyOperation(): ExternalOperationInputContent {
        let result = new ExternalOperationInputContent()
        result.expression = new InputContent<string>('');
        result.externalVariableValue = new InputContent<string>('');
        result.externalVariableOperation = new InputContent(ExternalVariableOperation.Insert);
        result.externalVariableKey = new InputContent<string>('');
        return result;
    }

    createEmptyData(){
        this.data = new InternalVariableEditItem();
        this.data.id = undefined;
        this.data.key = new InputContent('');
        this.data.type = new InputContent(InternalVariableType.Number);
        this.data.defaultValue = new InputContent('');
        this.data.subTableName = new InputContent('');
        this.data.resetTime = new InputContent('');
        this.data.operations = [];
    }
    
    private FillData(id: number) {
        this.internalVariablesConfigurationService.apiInternalVariablesConfigurationsIdGet({id})
            .then(configuration =>
                this.internalVariablesConfigurationService.apiInternalVariablesConfigurationsIdOperationsGet({id: configuration.id!})
                    .then(r => {
                        runInAction(() => {
                            if(!this.data) this.data = new InternalVariableEditItem();
                            this.setId(configuration.id);
                            this.setKey(configuration.key || '');
                            this.setType(configuration.type!);
                            this.setDefaultValue(configuration.defaultValue || '');
                            this.setSubTableName(configuration.subTableName || '');
                            this.setResetTime(configuration.resetTime || '');
                            this.setOperations(r);
                        })
                    })
            )
    }

    @action loadDataIfIdProvided() {
        if (typeof(this.id) !== typeof(undefined))
        {
            this.createEmptyData();
            this.FillData(this.id!);
        }
        else{
            this.createEmptyData();
        }
    }

    @action setInternalVariableTypes(r: InternalVariableTypesResult) {
        this.internalVariableTypes = r.internalVariableTypeNames?.map((x :string) => new DropDownOptions (InternalVariableType[x as keyof typeof InternalVariableType], x)) || []
    }

    @action setExternalOperationVariableTypes(r: ExternalVariableOperationTypesResult) {
        this.externalVariableOperationTypes = r.externalVariableOperationTypes?.map((x :string) => new DropDownOptions (ExternalVariableOperation[x as keyof typeof ExternalVariableOperation], x)) || []
    }

    @action setId(id: number | undefined) {
        this.data!.id = id;
    }

    @action setOperations(r: Array<InternalVariableOperationConfigurationOut>) {
        this.data!.operations = r.map((x: any) => {
            let e = new ExternalOperationInputContent();
            e.id = x.id;
            e.externalVariableValue = new InputContent(x.externalVariableValue);
            e.externalVariableOperation = new InputContent(x.externalVariableOperation);
            e.externalVariableKey = new InputContent(x.externalVariableKey);
            e.expression = new InputContent(x.expression);
            e.operationIdentifier = new InputContent(x.operationIdentifier);
            return e;
        });
    }

    private backToList() {
        window.location.replace("/internal-variables-configuration")
    }

    private handleError(r: any) {
        if (r.status === 400)
        {
            Swal.fire({
                title: 'Error',
                confirmButtonText: 'Ok',
                icon: 'error'
            }).then(() => r.json().then((j: any) => {if (j.errors) this.parseErrors(j.errors)}));
        }
    }
}