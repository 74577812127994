import {ReactNode} from 'react';
import * as React from 'react';
import clsx from 'clsx';
import {observer} from 'mobx-react';
import {
    AppBar, Button,
    createStyles,
    CssBaseline, Divider, Drawer,
    IconButton, ListItem, ListItemIcon, ListItemText,
    makeStyles, Menu, MenuItem,
    Theme, Toolbar,
    Typography,
    useTheme
} from '@material-ui/core';
import {LayoutState} from './LayoutState';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CallMissedIcon from '@material-ui/icons/CallMissed';
import CallMissedOutgoingIcon from '@material-ui/icons/CallMissedOutgoing';
import ListIcon from '@material-ui/icons/List';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import {mainStore} from '../mainStore';
import {Link, Link as RouterLink, LinkProps as RouterLinkProps, NavLink} from 'react-router-dom';
import {Container, NavItem} from 'react-bootstrap';

interface LayoutProps{
    content: ReactNode;
    state: LayoutState;
    path: string | undefined;
}

const drawerWidth = 300;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            background: 'rgb(255, 255, 255)',
            color: 'rgba(0, 0, 0, 0.54) '
        },
        appBarShift: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            marginRight: 36,
        },
        hide: {
            display: 'none',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
        },
        drawerOpen: {
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: theme.spacing(7) + 1,
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9) + 1,
            },
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            marginLeft: -drawerWidth,
        },
        contentShift: {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        },
    }),
);

interface ListItemLinkProps {
    icon?: React.ReactElement;
    primary: string;
    to: string;
}

function ListItemLink(props: ListItemLinkProps) {
    const { icon, primary, to } = props;

    const renderLink = React.useMemo(
        () =>
            React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
                <RouterLink to={to} ref={ref} {...itemProps} />
            )),
        [to],
    );

    return (
            <ListItem component={renderLink} style={{padding: '0'}}>
                <ListItemText style={{color: 'black', textAlign: 'left'}} primary={primary} />
            </ListItem>
    );
}

export const Layout: React.FC<LayoutProps> = observer( ({content, state, path}) => 
{
    const classes = useStyles();
    const theme = useTheme();
    const currentPath = path;

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    
    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: state.isDrawerOpen,
                })}
            >
                <Toolbar>
                    <IconButton
                        aria-label="open drawer"
                        onClick={() => state.openDrawer()}
                        edge="start"
                        className={clsx(classes.menuButton, {
                            [classes.hide]: state.isDrawerOpen,
                        })}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" style={{flexGrow: 1}}>
                        sDSRelay | {currentPath}
                    </Typography>
                    <IconButton
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        color="inherit"
                    >
                        {mainStore.user && <AccountCircleIcon />}
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem><ListItemLink to="/password-change" primary="Change password" /></MenuItem>
                        <MenuItem onClick={() => {
                            handleClose();
                            mainStore.logout();
                        }}>Logout</MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: state.isDrawerOpen,
                    [classes.drawerClose]: !state.isDrawerOpen,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: state.isDrawerOpen,
                        [classes.drawerClose]: !state.isDrawerOpen,
                    }),
                }}
            >
                <div className={classes.toolbar}>
                    <IconButton onClick={() => state.closeDrawer()}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </div>
                <Divider />
                <Container>
                    <ul className="navbar-nav flex-grow">
                        <Button style={{display: 'block'}} onClick={() => state.toggleCurrentValue()}>
                            <NavItem>
                                <div style={{display: 'flex'}}><ListIcon />{state.isDrawerOpen && <div style={{paddingLeft: "10px"}}>Current Values</div>}</div>
                            </NavItem>
                        </Button>
                        {
                            state.isCurrentValuesOpen && (<ul className="navbar-nav flex-grow" style={{paddingLeft: '50px'}}>
                                <Button style={{display: 'block'}}>
                                    <NavItem>
                                        <NavLink className="text-dark text-decoration-none" to="/internal-variable-values"><div style={{display: 'flex'}}><div>Internal Variables</div></div></NavLink>
                                    </NavItem>
                                </Button>
                                <Button style={{display: 'block'}}>
                                    <NavItem>
                                        <NavLink className="text-dark text-decoration-none" to="/external-variable-values"><div style={{display: 'flex'}}><div>External Variables</div></div></NavLink>
                                    </NavItem>
                                </Button>
                            </ul>)
                        }
                        <Button style={{display: 'block'}}>
                            <NavItem>
                                <NavLink className="text-dark text-decoration-none" to="/internal-variables-configuration"><div style={{display: 'flex'}}><CallMissedOutgoingIcon />{state.isDrawerOpen && <div style={{paddingLeft: "10px"}}>Internal Configuration</div>}</div></NavLink>
                            </NavItem>
                        </Button>
                        <Button style={{display: 'block'}}>
                            <NavItem>
                                <NavLink className="text-dark text-decoration-none" to="/external-variables-configuration"><div style={{display: 'flex'}}><CallMissedIcon/>{state.isDrawerOpen && <div style={{paddingLeft: "10px"}}>External Configuration</div>}</div></NavLink>
                            </NavItem>
                        </Button>
                        <Button style={{display: 'block'}}>
                            <NavItem>
                                <NavLink className="text-dark text-decoration-none" to="/variable-import-configurations"><div style={{display: 'flex'}}><ImportExportIcon />{state.isDrawerOpen && <div style={{paddingLeft: "10px"}}>Import Configuration</div>}</div></NavLink>
                            </NavItem>
                        </Button>
                        <Button style={{display: 'block'}}>
                            <NavItem>
                                <NavLink className="text-dark text-decoration-none" to="/worker-status"><div style={{display: 'flex'}}><DeveloperBoardIcon />{state.isDrawerOpen && <div style={{paddingLeft: "10px"}}>Worker status</div>}</div></NavLink>
                            </NavItem>
                        </Button>
                        <Button style={{display: 'block'}}>
                            <NavItem>
                                <NavLink className="text-dark text-decoration-none" to="/users"><div style={{display: 'flex'}}><AccountBoxIcon />{state.isDrawerOpen && <div style={{paddingLeft: "10px"}}>Users</div>}</div></NavLink>
                            </NavItem>
                        </Button>
                    </ul>
                </Container>
            </Drawer>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <div className="router-outlet">
                    <Container>
                        {content}
                    </Container>
                </div>
            </main>
        </div>
    )
})