import {VariableImportWizardState} from '../VariableImportWizardState';
import * as React from 'react';
import {observer} from 'mobx-react';
import {Button, Checkbox} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

class VariableImportStep1Props{
    state: VariableImportWizardState;
}

export const VariableImportStep1: React.FC<VariableImportStep1Props> = observer(({state}) => {
    return (
        <div>
            <h4>Variables</h4>
            <TextField
                required
                label="Table name"
                value={state.editItem.tableName.value}
                onChange={event => state.setTableName(event.target.value)}
                margin="normal"
                error={state.editItem.tableName.errorText.length === 0 ? false : true}
                helperText={state.editItem.tableName.errorText}
                />
            <ul style={{"listStyleType": "none", "overflowY": "scroll", "maxHeight": "70vh"}}>
                {state.editItem.parsedVariables.map((v, index) =>{
                    return (
                        <li key={v.key}><Checkbox key={v.key} checked={state.isSelected(v.key!)} onChange={(e) => state.setIsFetchable(index, e.target.checked)}/>
                            { v.key + " - " + v.description }
                        </li>)
                })}
            </ul>

            {state.editItem.tableName.value && <Button style={{"float": "right"}} onClick={() => state.save()}>Save</Button>}
        </div>
    )});