/* tslint:disable */
/* eslint-disable */
/**
 * sDSRelay API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExternalVariableOperation,
    ExternalVariableOperationFromJSON,
    ExternalVariableOperationFromJSONTyped,
    ExternalVariableOperationToJSON,
    InternalVariableConfigurationDto,
    InternalVariableConfigurationDtoFromJSON,
    InternalVariableConfigurationDtoFromJSONTyped,
    InternalVariableConfigurationDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface InternalVariableOperationConfigurationDto
 */
export interface InternalVariableOperationConfigurationDto {
    /**
     * 
     * @type {number}
     * @memberof InternalVariableOperationConfigurationDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof InternalVariableOperationConfigurationDto
     */
    internalVariableConfigurationId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InternalVariableOperationConfigurationDto
     */
    operationIdentifier?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternalVariableOperationConfigurationDto
     */
    externalVariableKey?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternalVariableOperationConfigurationDto
     */
    externalVariableValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternalVariableOperationConfigurationDto
     */
    expression?: string | null;
    /**
     * 
     * @type {ExternalVariableOperation}
     * @memberof InternalVariableOperationConfigurationDto
     */
    externalVariableOperation?: ExternalVariableOperation;
    /**
     * 
     * @type {InternalVariableConfigurationDto}
     * @memberof InternalVariableOperationConfigurationDto
     */
    internalVariableConfiguration?: InternalVariableConfigurationDto;
    /**
     * 
     * @type {Date}
     * @memberof InternalVariableOperationConfigurationDto
     */
    insertDateTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InternalVariableOperationConfigurationDto
     */
    lastUpdateDateTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof InternalVariableOperationConfigurationDto
     */
    readonly identifier?: string | null;
}

export function InternalVariableOperationConfigurationDtoFromJSON(json: any): InternalVariableOperationConfigurationDto {
    return InternalVariableOperationConfigurationDtoFromJSONTyped(json, false);
}

export function InternalVariableOperationConfigurationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternalVariableOperationConfigurationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'internalVariableConfigurationId': !exists(json, 'internalVariableConfigurationId') ? undefined : json['internalVariableConfigurationId'],
        'operationIdentifier': !exists(json, 'operationIdentifier') ? undefined : json['operationIdentifier'],
        'externalVariableKey': !exists(json, 'externalVariableKey') ? undefined : json['externalVariableKey'],
        'externalVariableValue': !exists(json, 'externalVariableValue') ? undefined : json['externalVariableValue'],
        'expression': !exists(json, 'expression') ? undefined : json['expression'],
        'externalVariableOperation': !exists(json, 'externalVariableOperation') ? undefined : ExternalVariableOperationFromJSON(json['externalVariableOperation']),
        'internalVariableConfiguration': !exists(json, 'internalVariableConfiguration') ? undefined : InternalVariableConfigurationDtoFromJSON(json['internalVariableConfiguration']),
        'insertDateTime': !exists(json, 'insertDateTime') ? undefined : (new Date(json['insertDateTime'])),
        'lastUpdateDateTime': !exists(json, 'lastUpdateDateTime') ? undefined : (new Date(json['lastUpdateDateTime'])),
        'identifier': !exists(json, 'identifier') ? undefined : json['identifier'],
    };
}

export function InternalVariableOperationConfigurationDtoToJSON(value?: InternalVariableOperationConfigurationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'internalVariableConfigurationId': value.internalVariableConfigurationId,
        'operationIdentifier': value.operationIdentifier,
        'externalVariableKey': value.externalVariableKey,
        'externalVariableValue': value.externalVariableValue,
        'expression': value.expression,
        'externalVariableOperation': ExternalVariableOperationToJSON(value.externalVariableOperation),
        'internalVariableConfiguration': InternalVariableConfigurationDtoToJSON(value.internalVariableConfiguration),
        'insertDateTime': value.insertDateTime === undefined ? undefined : (value.insertDateTime.toISOString()),
        'lastUpdateDateTime': value.lastUpdateDateTime === undefined ? undefined : (value.lastUpdateDateTime.toISOString()),
    };
}


