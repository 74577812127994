import {
    ApiUsersPostRequest, UserCreateIn,
    UsersApi
} from '../../../api';
import {action, makeObservable, observable} from 'mobx';
import {
    ExtractedError,
    IErrorText,
    InputContent,
} from '../VariablesConfiguration/EditInternalVariablesConfigurationState';

export class AccountEditState {
    private _usersService: UsersApi;

    @observable data: any;
    
    constructor(_usersService : UsersApi) {
        makeObservable(this);
        this._usersService = _usersService;
        this.createEmptyData();
    }

    @action private createEmptyData() {
        this.data = new class {
            name : InputContent<string> = new InputContent('')
            email: InputContent<string> = new InputContent('')
            password: InputContent<string> = new InputContent('')
        }
    }

    @action setName(value: string) {
        this.data.name.value = value;
    }

    @action setEmail(value: string) {
        this.data.email.value = value;
    }

    @action setPassword(value: string) {
        this.data.password.value = value;
    }

    save() {
        let data = this.data;
        this._usersService.apiUsersPost(new class implements ApiUsersPostRequest {
            userCreateIn: UserCreateIn = new class implements UserCreateIn {
                email: string | null = data.email.value;
                name: string | null = data.name.value;
                password: string | null = data.password.value;
            };
        }).then(r => {window.location.replace('/users')}).catch(r => {
            if (r.status === 400)
            {
                return r.json()
            }
        }).then(j => this.parseErrors(j.errors));
    }

    @action private parseErrors(errors: any[]) {
        let extractedErrors = this.extractErrors(errors)

        if (this instanceof AccountEditState) {
            extractedErrors.forEach(e => {
                // @ts-ignore
                let property = this.data[e.key.toLowerCase() as keyof any] as IErrorText;
                property.setErrorText(e.error);
            })
        }
    }

    private extractErrors(errors: any): ExtractedError[]  {
        return Object.keys(errors).map(key => new ExtractedError(key, errors[key]));
    }
}