/* tslint:disable */
/* eslint-disable */
/**
 * sDSRelay API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExternalVariableOperation,
    ExternalVariableOperationFromJSON,
    ExternalVariableOperationFromJSONTyped,
    ExternalVariableOperationToJSON,
} from './';

/**
 * 
 * @export
 * @interface InternalVariableOperationConfigurationIn
 */
export interface InternalVariableOperationConfigurationIn {
    /**
     * 
     * @type {number}
     * @memberof InternalVariableOperationConfigurationIn
     */
    id?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InternalVariableOperationConfigurationIn
     */
    operationIdentifier?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternalVariableOperationConfigurationIn
     */
    externalVariableKey?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternalVariableOperationConfigurationIn
     */
    externalVariableValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InternalVariableOperationConfigurationIn
     */
    expression?: string | null;
    /**
     * 
     * @type {ExternalVariableOperation}
     * @memberof InternalVariableOperationConfigurationIn
     */
    externalVariableOperation?: ExternalVariableOperation;
}

export function InternalVariableOperationConfigurationInFromJSON(json: any): InternalVariableOperationConfigurationIn {
    return InternalVariableOperationConfigurationInFromJSONTyped(json, false);
}

export function InternalVariableOperationConfigurationInFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternalVariableOperationConfigurationIn {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'operationIdentifier': !exists(json, 'operationIdentifier') ? undefined : json['operationIdentifier'],
        'externalVariableKey': !exists(json, 'externalVariableKey') ? undefined : json['externalVariableKey'],
        'externalVariableValue': !exists(json, 'externalVariableValue') ? undefined : json['externalVariableValue'],
        'expression': !exists(json, 'expression') ? undefined : json['expression'],
        'externalVariableOperation': !exists(json, 'externalVariableOperation') ? undefined : ExternalVariableOperationFromJSON(json['externalVariableOperation']),
    };
}

export function InternalVariableOperationConfigurationInToJSON(value?: InternalVariableOperationConfigurationIn | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'operationIdentifier': value.operationIdentifier,
        'externalVariableKey': value.externalVariableKey,
        'externalVariableValue': value.externalVariableValue,
        'expression': value.expression,
        'externalVariableOperation': ExternalVariableOperationToJSON(value.externalVariableOperation),
    };
}


