/* tslint:disable */
/* eslint-disable */
/**
 * sDSRelay API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    VariablesImportListItem,
    VariablesImportListItemFromJSON,
    VariablesImportListItemFromJSONTyped,
    VariablesImportListItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface VariablesImportConfigurationOut
 */
export interface VariablesImportConfigurationOut {
    /**
     * 
     * @type {Array<VariablesImportListItem>}
     * @memberof VariablesImportConfigurationOut
     */
    items?: Array<VariablesImportListItem> | null;
}

export function VariablesImportConfigurationOutFromJSON(json: any): VariablesImportConfigurationOut {
    return VariablesImportConfigurationOutFromJSONTyped(json, false);
}

export function VariablesImportConfigurationOutFromJSONTyped(json: any, ignoreDiscriminator: boolean): VariablesImportConfigurationOut {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(VariablesImportListItemFromJSON)),
    };
}

export function VariablesImportConfigurationOutToJSON(value?: VariablesImportConfigurationOut | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(VariablesImportListItemToJSON)),
    };
}


