import {VariableImportWizardState} from '../VariableImportWizardState';
import * as React from 'react';
import {observer} from 'mobx-react';
import {Table, TableBody, TableCell, TableRow} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import _ from "lodash"
import {ParserType} from '../../../../../api';

export class VariableImportStep0Props{
    state: VariableImportWizardState;
}

export const VariableImportStep0: React.FC<VariableImportStep0Props> = observer(({state}) => {
    return (
        <div>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <TextField
                                required
                                label="Name"
                                value={state.editItem.name.value}
                                onChange={event => state.setName(event.target.value)}
                                margin="normal"
                                error={state.editItem.name.errorText.length === 0 ? false : true}
                                helperText={state.editItem.name.errorText}
                            />
                        </TableCell>
                        <TableCell>
                            <TextField
                                required
                                select
                                label="Type:"
                                value={state.editItem.parserType.value}
                                onChange={(event) => state.setParserType( ParserType[event.target.value as keyof typeof ParserType])}
                                SelectProps={{
                                    native: true,
                                    MenuProps: {
                                    },
                                }}
                                InputProps={{
                                }}
                                margin="normal"
                                variant="filled"
                            >
                                <option />
                                {state.parsers.map((option: ParserType, index: number) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </TextField>
                        </TableCell>
                        <TableCell>
                            <TextField
                                required
                                select
                                style={{"width": '100%'}}
                                label="Refresh minutes"
                                value={state.refreshMinutes}
                                onChange={(event) => state.setRefreshMinutes(event.target.value)}
                                SelectProps={{
                                    native: true,
                                    MenuProps: {
                                    },
                                }}
                                InputProps={{
                                }}
                                margin="normal"
                                variant="filled"
                            >
                                {
                                    _.range(60).map((option: number, index: number) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </TextField>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={3}>
                            <TextField
                                required
                                style={{"width": '100%'}}
                                label="Source path"
                                value={state.editItem.sourcePath.value}
                                onChange={event => state.setSourcePath(event.target.value)}
                                margin="normal"
                                error={state.editItem.sourcePath.errorText.length === 0 ? false : true}
                                helperText={state.editItem.sourcePath.errorText}
                            />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    )});