/* tslint:disable */
/* eslint-disable */
/**
 * sDSRelay API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VariableImportEditItem
 */
export interface VariableImportEditItem {
    /**
     * 
     * @type {string}
     * @memberof VariableImportEditItem
     */
    variableKey?: string | null;
}

export function VariableImportEditItemFromJSON(json: any): VariableImportEditItem {
    return VariableImportEditItemFromJSONTyped(json, false);
}

export function VariableImportEditItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): VariableImportEditItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'variableKey': !exists(json, 'variableKey') ? undefined : json['variableKey'],
    };
}

export function VariableImportEditItemToJSON(value?: VariableImportEditItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'variableKey': value.variableKey,
    };
}


