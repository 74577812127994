import * as React from 'react';
import {ExternalVariablesConfigurationState} from './ExternalVariablesConfigurationState';
import Paper from '@material-ui/core/Paper';
import {Button, Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import {observer} from 'mobx-react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import _ from 'lodash';
import {ConfirmDeleteDialog} from '../Common/ConfirmDeleteDialog';
import {NavLink} from 'react-router-dom';

interface ExternalVariablesConfigurationProps {
    state: ExternalVariablesConfigurationState;
}

export const ExternalVariablesConfiguration: React.FC<ExternalVariablesConfigurationProps> = observer(({state}) => {
    const data = state.externalVariablesConfigurations;
    return (
        <div>
        <Paper>
            <DragDropContext onDragEnd={result => state.onDragEnd(result)}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Type name</TableCell>
                                    <TableCell>Matching pattern</TableCell>
                                    <TableCell>Reset time</TableCell>
                                    <TableCell>Life time</TableCell>
                                    <TableCell>Default value</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                                {
                                    _.orderBy(data, ['matchOrder'])
                                        .map((row, index) => {
                                            return (
                                                <Draggable key={row.id}
                                                           draggableId={row.id.toString()}
                                                           index={index}>
                                                    {(provided, snapshot) => (
                                                        <TableRow
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}>
                                                            <TableCell>
                                                                <p>{row.typeName}</p>
                                                            </TableCell>
                                                            <TableCell>
                                                                <p>{row.matchingPattern}</p>
                                                            </TableCell>
                                                            <TableCell>
                                                                <p>{row.defaultResetTime}</p>
                                                            </TableCell>
                                                            <TableCell>
                                                                <p>{row.defaultLifetime}</p>
                                                            </TableCell>
                                                            <TableCell>
                                                                <p>{row.defaultValue}</p>
                                                            </TableCell>
                                                            <TableCell colSpan={2}>
                                                                <Button onClick={() => {}}>
                                                                    <NavLink className="text-dark" to={"/external-variables-configuration/edit/"+row.id}><EditIcon /></NavLink>
                                                                </Button>
                                                                <Button
                                                                    onClick={() => {state.delete(row)}} >
                                                                    <DeleteIcon />
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </Draggable>
                                            )
                                        })
                                }
                            </TableBody>
                        </Table>
                    )}
                </Droppable>
            </DragDropContext>
            <ConfirmDeleteDialog state={state.confirmDeleteDialogState} />
        </Paper>
        <Fab color="primary" aria-label="Add" style={{float: 'right'}}>
            <NavLink className="text-dark" to={"/external-variables-configuration/edit/"}><AddIcon /></NavLink>
        </Fab>
        <Button onClick={() => {state.reloadApp()}}>Reload app</Button>
    </div>
)})