import {action, makeObservable, observable} from 'mobx';
import {VariableValuesApi} from '../../../api';

export class ExternalVariableValuesState {
    @observable items: any;
    private isFetching: boolean;
    
    constructor(private _variableValuesService: VariableValuesApi) {
        makeObservable(this);
        this.loadData();
    }
    
    @action loadData() {
        if (this.isFetching)
            return;
        
        this.isFetching = true;
        this._variableValuesService.apiVariableValuesExternalGet().then(r => this.items =  JSON.parse(r).data).finally(() => this.isFetching = false);
    }
}