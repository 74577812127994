import {action, makeObservable, observable} from 'mobx';
import {ConfirmDeleteDialogState} from '../Common/ConfirmDeleteDialogState';
import {CommonService} from '../../services/commonService';
import {
    ApiExternalVariableTypesConfigurationsIdDeleteRequest,
    ApiInternalVariablesConfigurationsIdDeleteRequest, InternalVariableConfigurationOut,
    InternalVariablesConfigurationApi,
    InternalVariableType
} from '../../../api';
import Swal from 'sweetalert2';

export class InternalVariablesConfiguration {
    id: number;
    key: string;
    type: InternalVariableType;
    subTableName: string;
    defaultValue: string;
    resetTime: string;
}

export class InternalVariablesConfigurationState {
    @observable internalVariablesConfigurations: InternalVariablesConfiguration[];
    confirmDeleteDialogState: ConfirmDeleteDialogState;
    constructor(private internalVariablesConfigurationService: InternalVariablesConfigurationApi,
                private commonService: CommonService) {
        makeObservable(this);
        this.loadData();
    }

    @action loadData() {
        this.internalVariablesConfigurationService.apiInternalVariablesConfigurationsGet().then(value => {
            this.setInternalVariableConfigurations(value);
        });
    }

    @action delete(entity: InternalVariablesConfiguration) {
        Swal.fire({
            title: 'Do you want to remove item?',
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: `No`,
        }).then((result) => {
            if (result.isConfirmed) {
                this.internalVariablesConfigurationService.apiInternalVariablesConfigurationsIdDelete(new class implements ApiInternalVariablesConfigurationsIdDeleteRequest {
                    id: number = entity.id;
                })
                    .then(x => this.internalVariablesConfigurations.splice(this.internalVariablesConfigurations.indexOf(entity)));
            }
        })
    }

    reloadApp() {
        this.commonService.reloadApp();
    }

    @action setInternalVariableConfigurations(value: Array<InternalVariableConfigurationOut>) {
        this.internalVariablesConfigurations = value.map((x: any) => {
            return x;
        })
    }
}