/* tslint:disable */
/* eslint-disable */
/**
 * sDSRelay API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ExternalVariableOperationTypesResult,
    ExternalVariableOperationTypesResultFromJSON,
    ExternalVariableOperationTypesResultToJSON,
    ExternalVariableTypeConfigurationIn,
    ExternalVariableTypeConfigurationInFromJSON,
    ExternalVariableTypeConfigurationInToJSON,
    ExternalVariableTypeDto,
    ExternalVariableTypeDtoFromJSON,
    ExternalVariableTypeDtoToJSON,
    ExternalVariableTypeOut,
    ExternalVariableTypeOutFromJSON,
    ExternalVariableTypeOutToJSON,
    ReorderRequestData,
    ReorderRequestDataFromJSON,
    ReorderRequestDataToJSON,
} from '../models';

export interface ApiExternalVariableTypesConfigurationsIdDeleteRequest {
    id: number;
}

export interface ApiExternalVariableTypesConfigurationsIdGetRequest {
    id: number;
}

export interface ApiExternalVariableTypesConfigurationsIdPutRequest {
    id: number;
    externalVariableTypeConfigurationIn?: ExternalVariableTypeConfigurationIn;
}

export interface ApiExternalVariableTypesConfigurationsPostRequest {
    externalVariableTypeConfigurationIn?: ExternalVariableTypeConfigurationIn;
}

export interface ApiExternalVariableTypesConfigurationsReorderPostRequest {
    reorderRequestData?: ReorderRequestData;
}

/**
 * 
 */
export class ExternalVariableTypesConfigurationApi extends runtime.BaseAPI {

    /**
     */
    async apiExternalVariableTypesConfigurationsExternalVariableOperationTypesGetRaw(): Promise<runtime.ApiResponse<ExternalVariableOperationTypesResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/external-variable-types-configurations/external-variable-operation-types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExternalVariableOperationTypesResultFromJSON(jsonValue));
    }

    /**
     */
    async apiExternalVariableTypesConfigurationsExternalVariableOperationTypesGet(): Promise<ExternalVariableOperationTypesResult> {
        const response = await this.apiExternalVariableTypesConfigurationsExternalVariableOperationTypesGetRaw();
        return await response.value();
    }

    /**
     */
    async apiExternalVariableTypesConfigurationsGetRaw(): Promise<runtime.ApiResponse<Array<ExternalVariableTypeOut>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/external-variable-types-configurations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ExternalVariableTypeOutFromJSON));
    }

    /**
     */
    async apiExternalVariableTypesConfigurationsGet(): Promise<Array<ExternalVariableTypeOut>> {
        const response = await this.apiExternalVariableTypesConfigurationsGetRaw();
        return await response.value();
    }

    /**
     */
    async apiExternalVariableTypesConfigurationsIdDeleteRaw(requestParameters: ApiExternalVariableTypesConfigurationsIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiExternalVariableTypesConfigurationsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/external-variable-types-configurations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiExternalVariableTypesConfigurationsIdDelete(requestParameters: ApiExternalVariableTypesConfigurationsIdDeleteRequest): Promise<void> {
        await this.apiExternalVariableTypesConfigurationsIdDeleteRaw(requestParameters);
    }

    /**
     */
    async apiExternalVariableTypesConfigurationsIdGetRaw(requestParameters: ApiExternalVariableTypesConfigurationsIdGetRequest): Promise<runtime.ApiResponse<ExternalVariableTypeOut>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiExternalVariableTypesConfigurationsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/external-variable-types-configurations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExternalVariableTypeOutFromJSON(jsonValue));
    }

    /**
     */
    async apiExternalVariableTypesConfigurationsIdGet(requestParameters: ApiExternalVariableTypesConfigurationsIdGetRequest): Promise<ExternalVariableTypeOut> {
        const response = await this.apiExternalVariableTypesConfigurationsIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiExternalVariableTypesConfigurationsIdPutRaw(requestParameters: ApiExternalVariableTypesConfigurationsIdPutRequest): Promise<runtime.ApiResponse<ExternalVariableTypeDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiExternalVariableTypesConfigurationsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/external-variable-types-configurations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ExternalVariableTypeConfigurationInToJSON(requestParameters.externalVariableTypeConfigurationIn),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExternalVariableTypeDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiExternalVariableTypesConfigurationsIdPut(requestParameters: ApiExternalVariableTypesConfigurationsIdPutRequest): Promise<ExternalVariableTypeDto> {
        const response = await this.apiExternalVariableTypesConfigurationsIdPutRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiExternalVariableTypesConfigurationsPostRaw(requestParameters: ApiExternalVariableTypesConfigurationsPostRequest): Promise<runtime.ApiResponse<ExternalVariableTypeDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/external-variable-types-configurations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExternalVariableTypeConfigurationInToJSON(requestParameters.externalVariableTypeConfigurationIn),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExternalVariableTypeDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiExternalVariableTypesConfigurationsPost(requestParameters: ApiExternalVariableTypesConfigurationsPostRequest): Promise<ExternalVariableTypeDto> {
        const response = await this.apiExternalVariableTypesConfigurationsPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiExternalVariableTypesConfigurationsReorderPostRaw(requestParameters: ApiExternalVariableTypesConfigurationsReorderPostRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/external-variable-types-configurations/reorder`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReorderRequestDataToJSON(requestParameters.reorderRequestData),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiExternalVariableTypesConfigurationsReorderPost(requestParameters: ApiExternalVariableTypesConfigurationsReorderPostRequest): Promise<void> {
        await this.apiExternalVariableTypesConfigurationsReorderPostRaw(requestParameters);
    }

}
