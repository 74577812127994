import * as React from 'react';
import {InternalVariablesConfigurationState} from './InternalVariablesConfigurationState';
import Paper from '@material-ui/core/Paper';
import {Button, Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import {observer} from 'mobx-react';
import _ from 'lodash';
import {ConfirmDeleteDialog} from '../Common/ConfirmDeleteDialog';
import {NavLink} from 'react-router-dom';

interface InternalVariablesConfigurationProps{
    state: InternalVariablesConfigurationState;
}

export const InternalVariablesConfiguration: React.FC<InternalVariablesConfigurationProps> = observer(({state}) => {
    return (
            <div>
                <Paper>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Key</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>SubTable Name</TableCell>
                                <TableCell>DefaultValue</TableCell>
                                <TableCell>Reset time</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                _.orderBy(state.internalVariablesConfigurations, ['key']).map(row => {
                                    return (
                                        <TableRow key={row.id}>
                                            <TableCell>
                                                <p>{row.key}</p>
                                            </TableCell>
                                            <TableCell>
                                                <p>{row.type}</p>
                                            </TableCell>
                                            <TableCell>
                                                <p>{row.subTableName}</p>
                                            </TableCell>
                                            <TableCell>
                                                <p>{row.defaultValue}</p>
                                            </TableCell>
                                            <TableCell>
                                                <p>{row.resetTime}</p>
                                            </TableCell>
                                            <TableCell colSpan={2}>
                                                <Button onClick={() => {}}>
                                                    <NavLink className="text-dark" to={"/internal-variables-configuration/edit/"+row.id}><EditIcon /></NavLink>
                                                </Button>
                                                <Button
                                                    onClick={() => {state.delete(row)}} >
                                                    <DeleteIcon />
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                    <ConfirmDeleteDialog state = {state.confirmDeleteDialogState} />
                </Paper>
                <Fab color="primary" aria-label="Add" style={{float: 'right'}}>
                    <NavLink className="text-dark" to={"/internal-variables-configuration/edit/"}><AddIcon /></NavLink>
                </Fab>
                <Button onClick={() => {state.reloadApp()}}>Reload app</Button>
            </div>
        )
})