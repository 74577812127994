import {CommonService} from '../../services/commonService';
import {ConfirmDeleteDialogState} from '../Common/ConfirmDeleteDialogState';
import {action, makeObservable, observable} from 'mobx';
import {ParserType, VariablesImportApi} from '../../../api';

export class VariableImportConfigurationListItem{
    id?: number;
    name?: string;
    parserType?: ParserType;
    refreshCron?: string;
}

export class VariableImportConfigurationState
{
    confirmDeleteDialogState: ConfirmDeleteDialogState;
    @observable importConfigurations: VariableImportConfigurationListItem[];
    
    constructor(private variablesImportService: VariablesImportApi) {
        makeObservable(this);
        this.loadData();
    }

    @action loadData() {
        this.variablesImportService.apiVariablesImportGet().then(value => {
            this.importConfigurations = value.items!.map((x) => {
                let result = new VariableImportConfigurationListItem();
                result.id = x.id; 
                result.name = x.name || undefined;
                result.parserType = x.parserType;
                result.refreshCron = x.refreshCron || undefined
                return result;
            })
        });
    }

    @action delete(id: number) {
        this.variablesImportService.apiVariablesImportIdDelete({id})
            .then(() => window.location.reload());
    }
}