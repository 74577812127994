import * as React from 'react';
import {observer} from 'mobx-react';
import {AccountListViewState} from './AccountListViewState';
import Paper from '@material-ui/core/Paper';
import {Button, Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import {NavLink} from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

export class AccountListViewProps{
    state : AccountListViewState;
}

export const AccountListView: React.FC<AccountListViewProps> = observer(({state}) => {
    return (
        <div>
            <Paper>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Login</TableCell>
                            <TableCell>Mail</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            state.items?.map(row => {
                                return (
                                    <TableRow key={row.id}>
                                        <TableCell>
                                            <p>{row.name}</p>
                                        </TableCell>
                                        <TableCell>
                                            <p>{row.email}</p>
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                onClick={() => {state.delete(row.id!)}} >
                                                <DeleteIcon />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </Paper>
            <Fab color="primary" aria-label="Add" style={{float: 'right'}}>
                <NavLink to="/users/edit" ><AddIcon style={{"color": "white"}} /></NavLink>
            </Fab>
        </div>
    )
})